import { useEffect } from 'react';
import { Row, Col, Tabs, PageHeader, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
	Switch,
	Route,
	useRouteMatch,
	useHistory,
	useLocation,
	Link,
} from 'react-router-dom';
import { setTabItem } from 'src/redux/ducks/camp';

import { RootState } from 'src/redux/ducks';
import Loading from 'src/components/Loading';
import CampListScreen from './Sections/CampList.screen';
import CampUserListScreen from './Sections/CampUserList.screen';
import CampCreateScreen from './Sections/Camp.create.screen';
import CampUpdateScreen from './Sections/Camp.update.screen';
import CampUserUpdateScreen from './Sections/Camp.user.update.screen';

import './index.scss';

const { TabPane } = Tabs;

const CampScreen = () => {
	const dispatch = useDispatch();
	let { path, url } = useRouteMatch();
	let history = useHistory();
	let { pathname } = useLocation();

	const selectedTabItem = useSelector((state: RootState) => state.camp.tabItem);
	const user = useSelector((state: RootState) => state.user.userData);
	const loading = false;

	useEffect(() => {
		const subPath = pathname.split('/');
		if (subPath.length > 2) {
			dispatch(setTabItem(subPath[2]));
		} else {
			dispatch(setTabItem(''));
		}
	}, [dispatch, pathname]);

	const onTabChange = (value: string) => {
		dispatch(setTabItem(value));

		if (value) {
			history.push(`${url}/${value}`);
		} else {
			history.push(`${url}`);
		}
	};

	return (
		<Loading loading={loading}>
			<Row>
				<Col span={24}>
					<PageHeader
						className="site-page-header no-left-padding"
						title={`Kamp Alanları`}
						extra={
							pathname === '/camp'
								? [
										<Button key={1} type={'primary'}>
											<Link to={`${url}/create`}>Yeni Alan Ekle</Link>
										</Button>,
								  ]
								: []
						}
					/>
				</Col>
				<Col
					span={24}
					style={{
						width: '100%',
						display: 'block',
						textAlign: 'left',
					}}
				>
					<div className="card-container">
						<Tabs
							type="card"
							defaultActiveKey="data"
							style={{ paddingBottom: 0 }}
							activeKey={selectedTabItem}
							onChange={onTabChange}
						>
							<TabPane tab="Alanlar" key="" />
							{user.role === 'ADMIN' && (
								<TabPane tab="Kullanıcı Alanları" key="userCampSites" />
							)}
						</Tabs>
					</div>
				</Col>
				<Col
					span={24}
					style={{
						width: '100%',
						display: 'block',
						textAlign: 'left',
						backgroundColor: 'white',
						padding: '0px 20px',
					}}
				>
					<Switch>
						<Route exact path={path} component={CampListScreen} />
						{user.role === 'ADMIN' && (
							<Route
								exact
								path={`${path}/userCampSites`}
								component={CampUserListScreen}
							/>
						)}
						<Route exact path={`${path}/create`} component={CampCreateScreen} />
						<Route
							exact
							path={`${path}/update/:campId`}
							component={CampUpdateScreen}
						/>
						{user.role === 'ADMIN' && (
							<Route
								exact
								path={`${path}/userCampSites/userUpdate/:campId`}
								component={CampUserUpdateScreen}
							/>
						)}
					</Switch>
				</Col>
			</Row>
		</Loading>
	);
};

export default CampScreen;
