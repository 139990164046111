import { useState, useEffect } from 'react';
import {
	Form,
	Input,
	Col,
	Row,
	Breadcrumb,
	PageHeader,
	message,
	Button,
} from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';

import UploadImage from 'src/components/UploadImage';
import Loading from 'src/components/Loading';
import { getFeedbackRequest, addFeedbackRequest } from '../network';

const { TextArea } = Input;

const tailLayout = {
	wrapperCol: { offset: 0, span: 24 },
};

const layout = {
	labelCol: { span: 3 },
	wrapperCol: { span: 22 },
};

interface MatchParams {
	feedbackId: string;
}

const FeedbackUpdateScreen = () => {
	const [form] = Form.useForm();
	let { params } = useRouteMatch<MatchParams>();

	const [feedbackData, setFeedbackData] = useState<any>({});
	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getFeedback();
	}, []);

	const getFeedback = async () => {
		const response: any = await getFeedbackRequest(params.feedbackId);
		const images: any = [];
		if (response.status) {
			setFeedbackData(response.data);

			response.data?.imagePaths?.forEach((img: any, index: number) => {
				images.push({
					url: img,
					status: 'done',
					uid: `img_${index}`,
				});
			});

			setImages(images);
			form.setFieldsValue(response.data);
			setLoading(false);
		}
	};

	const addFeedbackToComment = async () => {
		let { fullName, email, content, campInformation } = feedbackData;
		const params = {
			userFullName: fullName,
			email: email,
			comment: content,
			campId: campInformation.id,
		};
		const response: any = await addFeedbackRequest(params);
		if (response.status) {
			message.success('Successfull added as a new comment');
		} else {
			message.error(response.responseMessage);
		}
	};

	const onUploadChange = ({ uploadedImages, fileList }: any) => {
		console.log('uploadedImages', uploadedImages);
	};

	const onThumbnailChange = (url: string) => {
		console.log('thumbnail', url);
	};

	return (
		<Row>
			<Col span={24} style={{ paddingTop: 20 }}>
				<Breadcrumb>
					<Breadcrumb.Item>
						<Link to={'/feedback'}>Feedbacks</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>{feedbackData.title}</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title="Feedback Info"
				/>
			</Col>

			<Col span={24} style={{ marginTop: '20px' }}>
				<Loading loading={false}>
					<Form
						size={'large'}
						{...layout}
						form={form}
						name="control-ref"
						scrollToFirstError={true}
					>
						{!loading && (
							<>
								<Form.Item
									name={'imagePaths'}
									label={'Images'}
									rules={[{ required: true }]}
								>
									<UploadImage
										onChange={onUploadChange}
										imageData={images}
										bucketName={''}
										onThumbnailChange={onThumbnailChange}
									/>
								</Form.Item>
								<Form.Item
									name={'email'}
									label={'Emaill'}
									rules={[{ required: true }]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									name={'fullName'}
									label={'Full Name'}
									rules={[{ required: true }]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									name={'content'}
									label={'Message'}
									rules={[{ required: true }]}
								>
									<TextArea />
								</Form.Item>
								<Row gutter={8}>
									<Col span={12}>
										<Form.Item
											labelCol={{ span: 6 }}
											name={['campInformation', 'title']}
											label={'Camping Site'}
											rules={[{ required: true }]}
										>
											<Input />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											labelCol={{ span: 5 }}
											name={['campInformation', 'id']}
											label={'Camping Site ID'}
											rules={[{ required: true }]}
										>
											<Input />
										</Form.Item>
									</Col>
								</Row>
								<Form.Item {...tailLayout} style={{ textAlign: 'right' }}>
									<Button
										type="primary"
										htmlType="submit"
										style={{ width: '20%' }}
										onClick={addFeedbackToComment}
									>
										Yorum Olarak Ekle
									</Button>
								</Form.Item>
							</>
						)}
					</Form>
				</Loading>
			</Col>
		</Row>
	);
};

export default FeedbackUpdateScreen;
