import { Quill } from 'react-quill';
import QuillEditor from 'src/services/QuillEditor';
import { uploadImageRequest } from 'src/containers/Camp/network';
import { imageFileSelector } from 'src/utils/utilityFunctions';

const imageHandler = async () => {
	const input: any = document.createElement('input');
	input.setAttribute('type', 'file');
	input.setAttribute('accept', 'image/*');
	input.click();
	input.onchange = async () => {
		const file = input.files[0];
		const formData = new FormData();
		formData.append('quill-image', file);
		const quillRef = QuillEditor.quillRef.getEditor();
		const params = await imageFileSelector(file, null, false, '');
		const datam = {
			fileName: params.data,
			title: params.alias,
			type: params.contentType,
			bucketName: '.HtmlEditor',
		};

		const response = await uploadImageRequest(datam);
		if (response.status) {
			const range = quillRef.getSelection();
			const link = response.Location;
			quillRef.insertEmbed(range.index, 'image', link);
		}
	};
};

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = [
	'arial',
	'comic-sans',
	'courier-new',
	'georgia',
	'helvetica',
	'lucida',
];
Quill.register(Font, true);

var Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block, true);

const Image = Quill.import('formats/image');
Image.className = 'custom-class-to-image';
Quill.register(Image, true);

// Modules object for setting up the Quill editor
export const modules = {
	toolbar: {
		container: '#toolbar',
		handlers: {
			image: imageHandler,
		},
	},
	history: {
		delay: 500,
		maxStack: 100,
		userOnly: true,
	},
};

// Formats objects for setting up the Quill editor
export const formats = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'align',
	'strike',
	'script',
	'blockquote',
	'background',
	'list',
	'bullet',
	'indent',
	'link',
	'image',
	'color',
	'code-block',
];

// Quill Toolbar component
export const QuillToolbar = () => (
	<div id="toolbar">
		<span className="ql-formats">
			<select className="ql-font" defaultValue="arial">
				<option value="arial">Arial</option>
				<option value="comic-sans">Comic Sans</option>
				<option value="courier-new">Courier New</option>
				<option value="georgia">Georgia</option>
				<option value="helvetica">Helvetica</option>
				<option value="lucida">Lucida</option>
			</select>
			<select className="ql-size" defaultValue="medium">
				<option value="extra-small">Size 1</option>
				<option value="small">Size 2</option>
				<option value="medium">Size 3</option>
				<option value="large">Size 4</option>
			</select>
			<select className="ql-header" defaultValue="3">
				<option value="1">Heading</option>
				<option value="2">Subheading</option>
				<option value="3">Normal</option>
			</select>
		</span>
		<span className="ql-formats">
			<button className="ql-bold" />
			<button className="ql-italic" />
			<button className="ql-underline" />
			<button className="ql-strike" />
		</span>
		<span className="ql-formats">
			<button className="ql-list" value="ordered" />
			<button className="ql-list" value="bullet" />
			<button className="ql-indent" value="-1" />
			<button className="ql-indent" value="+1" />
		</span>
		<span className="ql-formats">
			<button className="ql-script" value="super" />
			<button className="ql-script" value="sub" />
			<button className="ql-blockquote" />
			<button className="ql-direction" />
		</span>
		<span className="ql-formats">
			<select className="ql-align" />
			<select className="ql-color" />
			<select className="ql-background" />
		</span>
		<span className="ql-formats">
			<button className="ql-link" />
			<button className="ql-image" />
			<button className="ql-video" />
		</span>
		<span className="ql-formats">
			<button className="ql-formula" />
			<button className="ql-code-block" />
			<button className="ql-clean" />
		</span>
	</div>
);

export default QuillToolbar;
