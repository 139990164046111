import { useState, useEffect } from 'react';
import { Form, Input, Button, Col, Row, Breadcrumb, PageHeader } from 'antd';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import UploadImage from 'src/components/UploadImage';
import HtmlEditor from 'src/components/HtmlEditor';
import Loading from 'src/components/Loading';
import { updateBlogRequest, getBlogRequest } from '../network';

const { TextArea } = Input;

const layout = {
	labelCol: { span: 3 },
	wrapperCol: { span: 22 },
};
const tailLayout = {
	wrapperCol: { offset: 0, span: 24 },
};

interface MatchParams {
	blogId: string;
}

const BlogUpdateScreen = () => {
	let history = useHistory();
	const [form] = Form.useForm();
	let { params } = useRouteMatch<MatchParams>();

	const [content, setContent] = useState('');
	const [blogData, setBlogData] = useState<any>({});
	const [uploadedImageData, setUploadedImageData] = useState<any>([]);
	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getBlog();
	}, []);

	const getBlog = async () => {
		const response: any = await getBlogRequest(params.blogId);
		const images: any = [];
		if (response.status) {
			setBlogData(response.data);
			images.push({
				url: response.data.image,
				status: 'done',
				uid: `img_${0}`,
			});
			setImages(images);
			setUploadedImageData(images);
			setContent(response.data.content);
			form.setFieldsValue(response.data);
			setLoading(false);
		}
	};

	const onUploadChange = ({ uploadedImages, fileList }: any) => {
		setUploadedImageData(fileList);
	};

	const onThumbnailChange = (url: string) => {
		// console.log('kek');
	};

	const onFinish = async (values: any) => {
		values.image = uploadedImageData?.[0]?.url;
		const params = { ...blogData, ...values };
		delete params.imagePaths;
		const response = await updateBlogRequest(params);
		if (response.status) {
			history.goBack();
		}
	};

	return (
		<Row>
			<Col span={24} style={{ paddingTop: 20 }}>
				<Breadcrumb>
					<Breadcrumb.Item>
						<Link to={'/blog'}>Makaleler</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>{blogData.title}</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title="Makale Alanı Ekle"
				/>
			</Col>

			<Col span={24} style={{ marginTop: '20px' }}>
				<Loading loading={false}>
					<Form
						size={'large'}
						{...layout}
						form={form}
						name="control-ref"
						scrollToFirstError={true}
						onFinish={onFinish}
					>
						{!loading && (
							<>
								<Form.Item
									name={'imagePaths'}
									label={'Kapak Fotoğrafı'}
									rules={[{ required: true }]}
								>
									<UploadImage
										onChange={onUploadChange}
										imageData={images}
										bucketName={'.Blogs'}
										onThumbnailChange={onThumbnailChange}
										single
									/>
								</Form.Item>
								<Form.Item
									name={'title'}
									label={'Başlık'}
									rules={[
										{ required: true },
										{
											max: 250,
											message: 'Başlık alanı 250 kelimeden fazla olamaz.',
										},
									]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									name={'description'}
									label={'Açıklama'}
									rules={[
										{ required: true },
										{
											max: 250,
											message: 'Açıklama alanı 250 kelimeden fazla olamaz.',
										},
									]}
								>
									<TextArea rows={4} />
								</Form.Item>
								<Form.Item
									label={'İçerik'}
									name={'content'}
									rules={[{ required: true }]}
								>
									<HtmlEditor value={content} onChange={setContent} />
								</Form.Item>
								<Row gutter={8}>
									<Col span={12}>
										<Form.Item
											labelCol={{ span: 6 }}
											name={'authorName'}
											label={'Yazar İsmi'}
											rules={[{ required: true }]}
										>
											<Input disabled />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											labelCol={{ span: 5 }}
											name={'authorProfile'}
											label={'Yazar Profili'}
											rules={[{ required: true }]}
										>
											<Input disabled />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={8}>
									<Col span={12}>
										<Form.Item
											labelCol={{ span: 6 }}
											name={'authorImage'}
											label={'Yazar Fotoğrafı'}
											rules={[{ required: true }]}
										>
											<Input disabled />
										</Form.Item>
									</Col>
								</Row>

								<Form.Item {...tailLayout} style={{ textAlign: 'right' }}>
									<Button
										type="primary"
										htmlType="submit"
										style={{ width: '20%' }}
									>
										Güncelle
									</Button>
								</Form.Item>
							</>
						)}
					</Form>
				</Loading>
			</Col>
		</Row>
	);
};

export default BlogUpdateScreen;
