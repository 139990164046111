import { useEffect, useState } from 'react';
import { Row, Col, PageHeader, Table, Popconfirm, Button, message } from 'antd';
import { useRouteMatch, Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import {
	SortableContainer,
	SortableElement,
	SortableHandle,
} from 'react-sortable-hoc';

import getColumnSearchProps from 'src/components/SearchableColumn';
import { getCmsListRequest, deleteCmsRequest } from '../network';

const CampListScreen = () => {
	let { url } = useRouteMatch();
	let [cmsData, setCmsData] = useState([]);
	let [loading, setLoading] = useState(false);

	const DragHandle = SortableHandle(() => (
		<MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
	));
	const SortableItem = SortableElement((props: any) => <tr {...props} />);
	const SortableBody = SortableContainer((props: any) => <tbody {...props} />);

	const columns: any = [
		{
			title: 'Sort',
			dataIndex: 'sort',
			width: 30,
			className: 'drag-visible',
			render: () => <DragHandle />,
		},
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			...getColumnSearchProps('title'),
			sorter: {
				compare: (a: any, b: any) => a.id - b.id,
			},
			render: (_: number, record: any) => {
				return (
					<Link style={{ marginRight: 15 }} to={`${url}/update/${record.id}`}>
						{record.title}
					</Link>
				);
			},
		},
		{
			title: 'Operation',
			dataIndex: 'Operation',
			editable: false,
			fixed: 'right',
			width: 150,
			render: (_: undefined, record: any) => (
				<Popconfirm
					title="Sure to delete?"
					onConfirm={() => deleteCms(record.id)}
				>
					<span style={{ color: 'red', cursor: 'pointer' }}>Delete</span>
				</Popconfirm>
			),
		},
	];

	useEffect(() => {
		getCmsList();
	}, []);

	const getCmsList = async () => {
		setLoading(true);
		const response = await getCmsListRequest();
		setLoading(false);
		if (response.status) {
			setCmsData(response.data);
		}
	};

	const deleteCms = async (id: string) => {
		const response: any = await deleteCmsRequest(id);
		if (response.status) {
			getCmsList();
			return message.success('Cms content successfully deleted');
		}
		message.error('Something happened');
	};

	const onSortEnd = ({ oldIndex, newIndex }: any) => {
		if (oldIndex !== newIndex) {
			const newData = arrayMoveImmutable(
				[].concat(cmsData),
				oldIndex,
				newIndex,
			).filter((el) => !!el);
			setCmsData(newData);
		}
	};

	const DraggableContainer = (props: any) => (
		<SortableBody
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			onSortEnd={onSortEnd}
			{...props}
		/>
	);

	const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = cmsData.findIndex(
			(x: any) => x.id === restProps['data-row-key'],
		);
		return <SortableItem index={index} {...restProps} />;
	};

	return (
		<Row>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title={`Cms List`}
					extra={[
						<Button key={1} type={'primary'}>
							<Link to={`${url}/create`}>Create new Cms Content</Link>
						</Button>,
					]}
				/>
			</Col>

			<Col span={24} style={{ marginTop: '15px' }}>
				<Table
					dataSource={cmsData}
					columns={columns}
					loading={loading}
					rowKey="id"
					components={{
						body: {
							wrapper: DraggableContainer,
							row: DraggableBodyRow,
						},
					}}
					// onChange={onTableChange}
					// pagination={{
					// 	current: currentPage,
					// 	pageSize: 20,
					// 	total: count,
					// }}
				/>
			</Col>
		</Row>
	);
};

export default CampListScreen;
