import { useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Col, PageHeader, Table, Popconfirm, message } from 'antd';
import { useRouteMatch, useLocation, Link } from 'react-router-dom';
import {
	getCommentListRequest,
	deleteCommentRequest,
	approveCommentRequest,
} from '../network';

const CampListScreen = () => {
	let location = useLocation();
	let { url } = useRouteMatch();

	let [currentPage, setCurrentPage] = useState<any>(1);
	let [commentData, setCommentData] = useState([]);
	let [count, setCount] = useState<any>(1);
	let [loading, setLoading] = useState(true);

	const columns: any = [
		{
			title: 'Full Name',
			dataIndex: 'userFullName',
			render: (_: number, record: any) => {
				return (
					<Link style={{ marginRight: 15 }} to={`${url}/update/${record.id}`}>
						{record.userFullName}
					</Link>
				);
			},
		},
		{
			title: 'Comment',
			dataIndex: 'comment',
			key: 'comment',
		},
		{
			title: 'Camp Id',
			dataIndex: 'campId',
			width: 150,
			render: (_: number, record: any) => {
				return (
					<Link style={{ marginRight: 15 }} to={`camp/update/${record.campId}`}>
						{record.campId}
					</Link>
				);
			},
		},
		{
			title: 'Created',
			dataIndex: 'createdAt',
			render: (_: number, record: any) => {
				return `${moment(record.createdAt).format('DD MMMM YYYY')}`;
			},
		},
		{
			title: 'Status',
			dataIndex: 'city',
			render: (_: number, record: any) => {
				return `${record.isApproved ? 'APPROVED' : 'NOT APPROVED'}`;
			},
		},
		{
			title: 'Operation',
			dataIndex: 'Operation',
			editable: false,
			fixed: 'right',
			width: 150,
			render: (_: undefined, record: any) => (
				<div>
					{!record.isApproved && (
						<Popconfirm
							title="Sure to approve?"
							onConfirm={() => approveComment(record.id)}
						>
							<span
								style={{ color: 'green', cursor: 'pointer', marginRight: 20 }}
							>
								Approve
							</span>
						</Popconfirm>
					)}
					<Popconfirm
						title="Sure to delete?"
						onConfirm={() => deleteComment(record.id)}
					>
						<span style={{ color: 'red', cursor: 'pointer' }}>Delete</span>
					</Popconfirm>
				</div>
			),
		},
	];

	useEffect(() => {
		const getPage = location.search.split('=')[1];
		if (getPage) {
			setCurrentPage(parseInt(getPage));
			getCommentList(getPage);
		} else {
			getCommentList(currentPage);
		}
	}, []);

	const getCommentList = async (page: any) => {
		const response = await getCommentListRequest(page);
		if (response.status) {
			setCommentData(response.commentData);
			setCount(response.count);
		}
		setLoading(false);
	};

	const onTableChange = (pagination: any) => {
		let _page = pagination.current;
		if (currentPage !== pagination.current) {
			window.history.replaceState(
				null,
				'',
				`/camp/?page=${pagination.current}`,
			);
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}

		setCurrentPage(pagination.current);
		getCommentList(_page);
	};

	const deleteComment = async (id: number) => {
		const response = await deleteCommentRequest(id);
		if (response.status) {
			message.success('Successfull Deleted');
			getCommentList(currentPage);
		} else {
			message.error('Oops!');
		}
	};

	const approveComment = async (id: number) => {
		const response = await approveCommentRequest(id);
		console.log('response', response);
		if (response.status) {
			message.success('Successfull Approved');
			getCommentList(currentPage);
		} else {
			message.error('Oops!');
		}
	};

	return (
		<Row>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title={`${count} Comments`}
				/>
			</Col>

			<Col span={24} style={{ marginTop: '15px' }}>
				<Table
					dataSource={commentData}
					// bordered
					columns={columns}
					loading={loading}
					onChange={onTableChange}
					pagination={{
						current: currentPage,
						pageSize: 20,
						total: count,
					}}
				/>
			</Col>
		</Row>
	);
};

export default CampListScreen;
