import { Modal, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/ducks';

const { TabPane } = Tabs;
interface Props {
	visible: any;
	setVisible: any;
}

const TutorialModal = ({ visible, setVisible }: Props) => {
	const user = useSelector((state: RootState) => state.user.userData);

	return (
		<Modal
			title="Nasıl kullanırım?"
			centered
			visible={visible}
			onOk={() => setVisible(false)}
			cancelButtonProps={{
				style: {
					display: 'none',
				},
			}}
			closable={false}
			okText="Tamam"
			width={1000}
			bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 300px)' }}
		>
			<Tabs defaultActiveKey={user?.permissions?.includes('CAMP') ? '1' : '2'}>
				{user?.permissions?.includes('CAMP') && (
					<TabPane tab="Tesis Ekleme" key="1">
						<br />
						<br />
						<img src="/kamp-ekleme.png" alt="" style={{ width: '100%' }} />
						<br />
						<br />
						<br />
						<p>
							<b>Fotoğraflar:</b> Tesisinizin fotoğraflarını ekleyebileceğiniz
							alan. Fotoğraflarınızı yükledikten sonra; kapak fotoğrafı yapmak
							istediğiniz fotoğrafın üzerindeki 'göz' ikonuna tıklayarak 'Make
							Thumbnail' butonuna tıklamanız gerekiyor. Bunu yapmadığınız
							taktirde ilk fotoğraf kapak fotoğrafınız olacaktır. Kapak
							fotoğrafı listelerde kullanıcılarımızın ilk göreceği fotoğraf
							olacaktır. Ayrıca yüklediğiniz fotoğrafların sırasını değiştirmek
							için 'sürükle-bırak' yapabilirsiniz.{' '}
						</p>
						<p>
							<b>Başlık:</b> Tesisinizin ismini girebileceğiniz alan.
							Tesisinizin adını tam girmeniz, site içi aramalarda ve arama
							motorlarında daha kolay bulunmasını sağlayacaktır.
						</p>
						<p>
							<b>Açıklama:</b> Tesisiniz hakkında bilgi verebileceğiniz alan.
							Tesisiniz ile ilgili bilgileri satırlar halinde ve imla
							kurallarına uyarak eklemeniz okunabilirliği arttıracaktır.
						</p>
						<p>
							<b>İl:</b> Tesisinizin bulunduğu ili seçebileceğiniz alan.
						</p>
						<p>
							<b>İlçe:</b> Tesisinizin bulunduğu ilçeyi girebileceğiniz alan.
							İlçe yerine, daha bilindik bir bölge, alan ismi de girebilirsiniz.
						</p>
						<p>
							<b>Bölge:</b> Tesisinizin bulunduğu bölgeyi seçebildiğiniz alan.
						</p>
						<p>
							<b>Kategori: </b> Tesisinizin bulunduğu bölgeyi tam olarak
							tanımlayan 6 özellikten birini seçebildiğiniz alan.
						</p>
						<p>
							<b>Tesis Telefon Numarası: </b> İletişim için kullandığınız
							birincil telefon numarasını girebileceğiniz alan. Eğer birden
							fazla numara kullanıyorsanız bunu 'Açıklama' kısmında
							belirtebilirsiniz.
						</p>
						<p>
							<b>Tesis Websitesi:</b> Tesisinizin web site bilgisini
							girebileceğiniz alan. Eğer bir web siteniz yoksa bu alana sosyal
							medya hesabınızı da yazabilirsiniz.
						</p>
						<p>
							<b>Tesis Sosyal Medya Hesabı:</b> Tesisinizin sosyal medya
							hesabını girebileceğiniz alan.
						</p>
						<p>
							<b>Özellikler:</b> Tesisinizde bulunan olanakları seçebildiğiniz
							alan.{' '}
						</p>
						<p>
							<b>Enlem-Boylam:</b> Tesisinizin haritalarda görüntülenmesi ve
							kullanıcıların yol tarifi alabilmeleri için enlem-boylam
							bilgilerini girmeniz gerekiyor.
						</p>
						<p>Enlem-boylam bilgilerine ulaşmak için:</p>
						<p>1- Bilgisayarınızda Google Haritalar'ı açın.</p>
						<p>2- Tesisinizin bulunduğu konuma sağ tıklayın.</p>
						<p>
							3- Açılan pencerede en üstteki enlem-boylam bilgilerinize sol
							tıklayarak kopyalayabilirsiniz.
						</p>
						<p>Virgülün sol tarafı enlem, sağ tarafı boylam bilgileridir.</p>
						<p style={{ color: 'red' }}>
							UYARI: Tesisiniz yayınlanmadan önce incelenecektir. Editörlerimiz
							gerekli gördüğü yerlerde düzenleme yapabilir.
						</p>
					</TabPane>
				)}
				{user?.permissions?.includes('EVENT') && (
					<TabPane tab="Etkinlik Ekleme" key="2">
						<br />
						<br />
						<img src="/etkinlik-ekleme.png" alt="" style={{ width: '100%' }} />
						<br />
						<br />
						<br />
						<p>
							<b>Kapak Fotoğrafı:</b> Etkinliğinizin afişini ekleyebileceğiniz
							alan. Yalnızca 1 görsel ekleyebilirsiniz.
						</p>
						<p>
							<b>Başlık:</b> Etkinliğinizin ismini girebileceğiniz alan. Örn:
							Yedigöller Bahar Kampı
						</p>
						<p>
							<b>Açıklama:</b> Etkinliğinizin kısa açıklamasını girebileceğiniz
							alan. Web sitemizde etkinlik kartında isimle birlikte bu kısa
							açıklama da görünecektir. Örn: Sonbahar'ın tadını Yedigöller'de
							çıkartın!
						</p>
						<p>
							<b>İçerik:</b> Etkinliğiniz ile ilgili bilgi girebileceğiniz alan.
							Etkinliğiniz ile ilgili bilgileri satırlar halinde ve imla
							kurallarına uyarak eklemeniz okunabilirliği arttıracaktır. Yazı
							kutusundaki editör araçlarını kullanarak, örneğin başlıklarınızı
							kalınlaştırabilir, önemli yerlerin altını çizebilir, liste aracını
							kullanarak fiyata nelerin dahil olduğunu listeler halinde
							yazabilirsiniz.
						</p>
						<p>
							<b>İl:</b> Etkinliğinizin yapılacağı ili girebileceğiniz alan.
						</p>
						<p>
							<b>Etkinlik Yeri:</b> Etkinliğinizin yapılacağı yeri
							girebileceğiniz alan. Örn: Seyrek Plajı
						</p>
						<p>
							<b>Başlangıç Tarihi:</b> Etkinliğinizin başladığı tarihi
							girebileceğiniz alan.
						</p>
						<p>
							<b>Bitiş Tarihi:</b> Etkinliğinizin bittiği tarihi girebileceğiniz
							alan.
						</p>
						<p>
							<b>Organizasyon Bilgi Linki:</b> Kullanıcıları etkinlikle ilgili
							yönlendireceğiniz link. Örn: web siteniz, bilet satış sitesi vb.
						</p>
						<p style={{ color: 'red' }}>
							UYARI: Etkinliğiniz yayınlanmadan önce incelenecektir.
							Editörlerimiz gerekli gördüğü yerlerde düzenleme yapabilir.
						</p>
					</TabPane>
				)}
			</Tabs>
		</Modal>
	);
};

export default TutorialModal;
