import { useState, useEffect } from 'react';
import moment from 'moment';
import {
	Form,
	Input,
	Button,
	Col,
	Row,
	Breadcrumb,
	PageHeader,
	Select,
	DatePicker,
	Switch,
} from 'antd';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import locale from 'antd/es/date-picker/locale/tr_TR';
import { useSelector } from 'react-redux';

import { RootState } from 'src/redux/ducks';
import { Cities } from 'src/utils/statics';
import UploadImage from 'src/components/UploadImage';
import HtmlEditor from 'src/components/HtmlEditor';
import Loading from 'src/components/Loading';
import { updateEventRequest, getEventRequest } from '../network';

const { TextArea } = Input;
const { Option } = Select;

const layout = {
	labelCol: { span: 3 },
	wrapperCol: { span: 22 },
};
const tailLayout = {
	wrapperCol: { offset: 0, span: 24 },
};

interface MatchParams {
	eventId: string;
}

const BlogUpdateScreen = () => {
	let history = useHistory();
	const [form] = Form.useForm();
	let { params } = useRouteMatch<MatchParams>();

	const user = useSelector((state: RootState) => state.user.userData);

	const [content, setContent] = useState('');
	const [eventData, setEventData] = useState<any>({});
	const [uploadedImageData, setUploadedImageData] = useState<any>([]);
	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getEvent();
	}, []);

	const getEvent = async () => {
		const response: any = await getEventRequest(params.eventId);
		const images: any = [];
		if (response.status) {
			setEventData(response.data);
			images.push({
				url: response.data.image,
				status: 'done',
				uid: `img_${0}`,
			});
			setImages(images);
			setUploadedImageData(images);
			setContent(response.data.content);
			response.data.startDate = moment(
				response.data.startDate,
				'YYYY/MM/DD HH:mm:ss',
			)
				.set('hour', 12)
				.set('minute', 0)
				.utcOffset(0, true);
			response.data.endDate = moment(
				response.data.endDate,
				'YYYY/MM/DD HH:mm:ss',
			)
				.set('hour', 12)
				.set('minute', 0)
				.utcOffset(0, true);
			form.setFieldsValue(response.data);
			setLoading(false);
		}
	};

	const onUploadChange = ({ uploadedImages, fileList }: any) => {
		setUploadedImageData(fileList);
	};

	const onThumbnailChange = (url: string) => {
		console.log('thumbnail', url);
	};

	const onFinish = async (values: any) => {
		values.image = uploadedImageData?.[0]?.url;
		const params = { ...eventData, ...values };
		params.content = params.content.replace(/span/g, 'p');
		delete params.imagePaths;
		const response = await updateEventRequest(params, params.id);
		if (response.status) {
			history.goBack();
		}
	};

	return (
		<Row>
			<Col span={24} style={{ paddingTop: 20 }}>
				<Breadcrumb>
					<Breadcrumb.Item>
						<Link to={'/event'}>Etkinlikler</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>{eventData.title}</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title="Etkinlik Alanını Güncelle"
				/>
			</Col>

			<Col span={24} style={{ marginTop: '20px' }}>
				<Loading loading={false}>
					<Form
						size={'large'}
						{...layout}
						form={form}
						name="control-ref"
						scrollToFirstError={true}
						onFinish={onFinish}
					>
						{!loading && (
							<>
								{user.role === 'ADMIN' && (
									<Form.Item
										valuePropName="checked"
										name={'isFeatured'}
										label={'Is Featured'}
									>
										<Switch />
									</Form.Item>
								)}
								<Form.Item
									name={'imagePaths'}
									label={'Kapak Fotoğrafı'}
									rules={[{ required: true }]}
								>
									<UploadImage
										onChange={onUploadChange}
										imageData={images}
										bucketName={'.Events'}
										onThumbnailChange={onThumbnailChange}
										single
									/>
								</Form.Item>
								<Form.Item
									name={'title'}
									label={'Başlık'}
									rules={[
										{ required: true },
										{
											max: 250,
											message: 'Başlık alanı 250 kelimeden fazla olamaz.',
										},
									]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									name={'description'}
									label={'Açıklama'}
									rules={[
										{ required: true },
										{
											max: 250,
											message: 'Açıklama alanı 250 kelimeden fazla olamaz.',
										},
									]}
								>
									<TextArea rows={4} />
								</Form.Item>
								<Form.Item
									name={'content'}
									label={'İçerik'}
									rules={[{ required: true }]}
								>
									<HtmlEditor value={content} onChange={setContent} />
								</Form.Item>
								<Row gutter={8}>
									<Col span={12}>
										<Form.Item
											labelCol={{ span: 6 }}
											name={'city'}
											label={'İl'}
											rules={[{ required: true }]}
										>
											<Select virtual={false} showSearch>
												{Cities.map((city, index) => (
													<Option key={index} value={city}>
														{city}
													</Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											labelCol={{ span: 5 }}
											name={'county'}
											label={'Yer'}
											rules={[{ required: true }]}
										>
											<Input />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={8}>
									<Col span={12}>
										<Form.Item
											labelCol={{ span: 6 }}
											name={'ownerName'}
											label={'Org. Sahibi'}
											rules={[{ required: true }]}
										>
											<Input disabled={user.role !== 'ADMIN'} />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											labelCol={{ span: 5 }}
											name={'ownerProfileUrl'}
											label={'Org. Profili'}
											rules={[{ required: true }]}
										>
											<Input disabled={user.role !== 'ADMIN'} />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={8}>
									<Col span={12}>
										<Form.Item
											labelCol={{ span: 6 }}
											name={'ownerProfileImage'}
											label={'Org. Profil Fotosu'}
											rules={[{ required: true }]}
										>
											<Input disabled={user.role !== 'ADMIN'} />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={8}>
									<Col span={12}>
										<Form.Item
											labelCol={{ span: 6 }}
											name={'startDate'}
											label={'Başlangıç Tarihi'}
											rules={[{ required: true }]}
										>
											<DatePicker
												format={'DD/MM/YYYY'}
												locale={locale}
												showTime={{
													defaultValue: moment('12:00:00', 'HH:mm:ss'),
												}}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											labelCol={{ span: 5 }}
											name={'endDate'}
											label={'Bitiş Tarihi'}
											rules={[{ required: true }]}
										>
											<DatePicker
												format={'DD/MM/YYYY'}
												locale={locale}
												showTime={{
													defaultValue: moment('12:00:00', 'HH:mm:ss'),
												}}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={8}>
									<Col span={12}>
										<Form.Item
											labelCol={{ span: 6 }}
											name={'price'}
											label={'Fiyat'}
											rules={[{ required: true }]}
										>
											<Input />
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item
											labelCol={{ span: 5 }}
											name={'externalUrl'}
											label={'Org. Bilgi Linki'}
											rules={[{ required: true }]}
										>
											<Input />
										</Form.Item>
									</Col>
								</Row>

								<Form.Item {...tailLayout} style={{ textAlign: 'right' }}>
									<Button
										type="primary"
										htmlType="submit"
										style={{ width: '20%' }}
									>
										Güncelle
									</Button>
								</Form.Item>
							</>
						)}
					</Form>
				</Loading>
			</Col>
		</Row>
	);
};

export default BlogUpdateScreen;
