import { request } from 'src/utils/request';

export async function getEventListRequest(page: number, pageSize: number) {
	const GET_EVENTS = `event/partnerEventsWithPagination?page=${page}&pageSize=${pageSize}`;
	try {
		const result: any = await request(GET_EVENTS, 'GET');
		if (result) {
			return { status: true, ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function getEventRequest(eventId: string) {
	const GET_EVENT = `event/getPartnerEvent/${eventId}`;
	try {
		const result: any = await request(GET_EVENT, 'GET');
		if (result) {
			return { ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function postEventRequest(data: any) {
	const POST_EVENT = `event/addEvent`;
	try {
		const result: any = await request(POST_EVENT, 'POST', data);
		if (result) {
			return { status: true, data: result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function updateEventRequest(data: any, eventId: string) {
	const UPDATE_EVENT = `event/updateEvent/${eventId}`;
	try {
		const result: any = await request(UPDATE_EVENT, 'PUT', data);
		if (result) {
			return { status: true, data: result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function deleteEventRequest(eventId: string) {
	const DELETE_EVENT = `event/deleteEvent/${eventId}`;
	try {
		const result: any = await request(DELETE_EVENT, 'DELETE');
		if (result.status) {
			return { status: true, ...result.data };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}
