import React from 'react';
import { Button, Col, Divider, Layout, Row, Form, Input, Modal } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from 'src/redux/ducks/user';
import { setLoading } from 'src/redux/ducks/app';
import helpers from 'src/utils/helpers';
import { adminLogin } from './network';

const { Content } = Layout;

const tailLayout = {
	wrapperCol: { offset: 0, span: 24 },
};

const Login = () => {
	let history = useHistory();
	const dispatch = useDispatch();

	const onFinish = async (values: any) => {
		const postData = { email: values.email, password: values.password };
		const response: any = await adminLogin(postData);
		dispatch(setLoading(false));

		if (response.status) {
			helpers.setAuthToken(response.token);
			helpers.setUserInfo(response.data);
			dispatch(setUser(response.data));
			history.push('/');
		} else {
			Modal.error({
				title: 'Hata',
				content: response.message,
			});
		}
	};

	const registerHandler = () => {
		history.push('/register');
	};

	return (
		<Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
			<Col
				xs={{ span: 18 }}
				lg={{ span: 12 }}
				xl={{ span: 10 }}
				md={{ span: 12 }}
				xxl={{ span: 6 }}
			>
				<Content style={{ overflow: 'initial' }}>
					<div
						className={'site-layout-background'}
						style={{
							padding: '48px',
							textAlign: 'center',
							backgroundColor: 'white',
							borderRadius: 10,
						}}
					>
						<img
							src={'https://kampp.in/logo.png'}
							style={{ height: '80px', objectFit: 'contain' }}
							alt={'Kampp Logo'}
						/>
						<Divider />
						<Form
							name="normal_login"
							className="login-form"
							initialValues={{ remember: true }}
							onFinish={onFinish}
						>
							<Form.Item
								name="email"
								label="Email"
								rules={[
									{ required: true, message: 'Please input your Username!' },
								]}
							>
								<Input
									prefix={<UserOutlined className="site-form-item-icon" />}
									placeholder="Email"
									size="large"
								/>
							</Form.Item>
							<Form.Item
								name="password"
								label="Şifre"
								rules={[
									{ required: true, message: 'Please input your Password!' },
								]}
							>
								<Input
									prefix={<LockOutlined className="site-form-item-icon" />}
									type="password"
									placeholder="Şifre"
									size="large"
								/>
							</Form.Item>
							<Form.Item {...tailLayout}>
								<Button
									type="primary"
									htmlType="submit"
									className="login-form-button"
									size="large"
									style={{ width: '150px' }}
								>
									Giriş Yap
								</Button>
								<Button
									style={{ width: '150px', marginLeft: 10 }}
									htmlType="button"
									size="large"
									onClick={registerHandler}
								>
									Hesap Oluştur
								</Button>
							</Form.Item>
						</Form>
						<Divider />
						Kampp Yazılım ©{new Date().getFullYear()} |{' '}
						<a href={'https://www.kampp.in'} target={'_blank'} rel="noreferrer">
							www.kampp.in
						</a>
						<br />
					</div>
				</Content>
			</Col>
		</Row>
	);
};

export default Login;
