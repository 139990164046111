import React from 'react';
import { PageHeader, Row, Col } from 'antd';

const HomeScreen = () => {
	return (
		<>
			<Row>
				<Col>
					<PageHeader
						className="site-page-header"
						backIcon={false}
						onBack={() => null}
						title="Genel Bakış"
						subTitle=""
					/>
				</Col>
			</Row>
		</>
	);
};

export default HomeScreen;
