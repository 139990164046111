import { useState, useEffect } from 'react';
import { Form, Input, Button, Col, Row, Breadcrumb, PageHeader } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from 'src/redux/ducks';
import UploadImage from 'src/components/UploadImage';
import HtmlEditor from 'src/components/HtmlEditor';
import Loading from 'src/components/Loading';
import { postBlogRequest } from '../network';

const { TextArea } = Input;

const layout = {
	labelCol: { span: 3 },
	wrapperCol: { span: 22 },
};
const tailLayout = {
	wrapperCol: { offset: 0, span: 24 },
};

const BlogCreateScreen = () => {
	let history = useHistory();
	const [form] = Form.useForm();

	const user = useSelector((state: RootState) => state.user.userData);

	const [value, setValue] = useState('');
	const [uploadedImageData, setUploadedImageData] = useState<any>([]);

	useEffect(() => {
		form.setFieldsValue({
			authorName: user.fullName,
			authorImage: user.profilePicture,
			authorProfile: user.profileSocialMedia,
		});
	}, []);

	const onUploadChange = ({ uploadedImages, fileList }: any) => {
		setUploadedImageData(fileList);
	};

	const onThumbnailChange = (url: string) => {
		// setThumbnail(url);
	};

	const onFinish = async (values: any) => {
		values.image = uploadedImageData?.[0]?.url;
		values.slug = generateSlugs(values.title);
		delete values.imagePaths;
		const response = await postBlogRequest(values);
		if (response.status) {
			history.goBack();
		}
	};

	const generateSlugs = (title: string) => {
		try {
			let text = title;
			var trMap = {
				çÇ: 'c',
				ğĞ: 'g',
				şŞ: 's',
				üÜ: 'u',
				ıİ: 'i',
				öÖ: 'o',
			};
			for (var key in trMap) {
				//@ts-ignore
				text = text.replace(new RegExp('[' + key + ']', 'g'), trMap[key]);
			}

			return text
				.replace(/[^-a-zA-Z0-9\s]+/gi, '')
				.replace(/\s/gi, '-')
				.replace(/[-]+/gi, '-')
				.toLowerCase();
		} catch (err) {
			return 'need-to-update';
		}
	};

	return (
		<Row>
			<Col span={24} style={{ paddingTop: 20 }}>
				<Breadcrumb>
					<Breadcrumb.Item>
						<Link to={'/blog'}>Makaleler</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>Yeni Alan</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title="Makale Alanı Ekle"
				/>
			</Col>

			<Col span={24} style={{ marginTop: '20px' }}>
				<Loading loading={false}>
					<Form
						size={'large'}
						{...layout}
						form={form}
						name="control-ref"
						scrollToFirstError={true}
						onFinish={onFinish}
					>
						<Form.Item
							name={'imagePaths'}
							label={'Kapak Fotoğrafı'}
							rules={[{ required: true }]}
						>
							<UploadImage
								onChange={onUploadChange}
								imageData={[]}
								bucketName={'.Blogs'}
								onThumbnailChange={onThumbnailChange}
							/>
						</Form.Item>
						<Form.Item
							name={'title'}
							label={'Başkık'}
							rules={[
								{ required: true },
								{
									max: 250,
									message: 'Başlık alanı 250 kelimeden fazla olamaz.',
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name={'description'}
							label={'Açıklama'}
							rules={[
								{ required: true },
								{
									max: 250,
									message: 'Açıklama alanı 250 kelimeden fazla olamaz.',
								},
							]}
						>
							<TextArea rows={4} />
						</Form.Item>
						<Form.Item
							name={'content'}
							label={'İçerik'}
							rules={[{ required: true }]}
						>
							<HtmlEditor value={value} onChange={setValue} />
						</Form.Item>
						<Row gutter={8}>
							<Col span={12}>
								<Form.Item
									labelCol={{ span: 6 }}
									name={'authorName'}
									label={'Yazar İsmi'}
									rules={[{ required: true }]}
								>
									<Input disabled />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									labelCol={{ span: 5 }}
									name={'authorProfile'}
									label={'Yazar Profili'}
									rules={[{ required: true }]}
								>
									<Input disabled />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={12}>
								<Form.Item
									labelCol={{ span: 6 }}
									name={'authorImage'}
									label={'Yazar Fotoğrafı'}
									rules={[{ required: true }]}
								>
									<Input disabled />
								</Form.Item>
							</Col>
						</Row>

						<Form.Item {...tailLayout} style={{ textAlign: 'right' }}>
							<Button type="primary" htmlType="submit" style={{ width: '20%' }}>
								Oluştur
							</Button>
						</Form.Item>
					</Form>
				</Loading>
			</Col>
		</Row>
	);
};

export default BlogCreateScreen;
