import { request } from 'src/utils/request';

export async function getMarketingMaterialsRequest() {
	const GET_MARKETINGS = `marketing`;
	try {
		const result: any = await request(GET_MARKETINGS, 'GET');
		if (result) {
			return { status: true, data: result.data };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function getMarketingMaterialRequest(marketingId: string) {
	const GET_MARKETING = `marketing/${marketingId}`;
	try {
		const result: any = await request(GET_MARKETING, 'GET');
		if (result) {
			return { ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function addMarketingMaterialRequest(params: any) {
	const ADD_MARKETING = `marketing`;
	try {
		const result: any = await request(ADD_MARKETING, 'POST', params);
		if (result) {
			return { ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function updateMarketingMaterialRequest(params: any) {
	const UPDATE_MARKETING = `marketing/${params.id}`;
	try {
		const result: any = await request(UPDATE_MARKETING, 'PUT', params);
		if (result) {
			return { ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function deleteMarketingMaterialRequest(marketingId: string) {
	const DELETE_MARKETING = `marketing/${marketingId}`;
	try {
		const result: any = await request(DELETE_MARKETING, 'DELETE');
		if (result) {
			return { ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}
