import { Col, Row, Checkbox, Form } from 'antd';

const Facilities = () => {
	return (
		<Form.Item
			name="facilities"
			label="Özellikler"
			style={{ padding: '15px 0px' }}
		>
			<Checkbox.Group>
				<Row>
					<Col span={3}>
						<Checkbox value="1" style={{ lineHeight: '32px' }}>
							Market/Büfe
						</Checkbox>
					</Col>
					<Col span={3}>
						<Checkbox value="2" style={{ lineHeight: '32px' }}>
							Piknik Masası
						</Checkbox>
					</Col>
					<Col span={3}>
						<Checkbox value="3" style={{ lineHeight: '32px' }}>
							Deniz
						</Checkbox>
					</Col>
					<Col span={3}>
						<Checkbox value="4" style={{ lineHeight: '32px' }}>
							Çeşme
						</Checkbox>
					</Col>
					<Col span={3}>
						<Checkbox value="5" style={{ lineHeight: '32px' }}>
							Tuvalet
						</Checkbox>
					</Col>
					<Col span={3}>
						<Checkbox value="6" style={{ lineHeight: '32px' }}>
							Ateş Yakılmaz
						</Checkbox>
					</Col>
					<Col span={3}>
						<Checkbox value="7" style={{ lineHeight: '32px' }}>
							Yabani Hayvan
						</Checkbox>
					</Col>
					<Col span={3}>
						<Checkbox value="8" style={{ lineHeight: '32px' }}>
							Sinyal Var
						</Checkbox>
					</Col>
					<Col span={3}>
						<Checkbox value="9" style={{ lineHeight: '32px' }}>
							Toplu Taşıma
						</Checkbox>
					</Col>
					<Col span={3}>
						<Checkbox value="10" style={{ lineHeight: '32px' }}>
							Otopark
						</Checkbox>
					</Col>
					<Col span={3}>
						<Checkbox value="11" style={{ lineHeight: '32px' }}>
							Karavan
						</Checkbox>
					</Col>
					<Col span={3}>
						<Checkbox value="12" style={{ lineHeight: '32px' }}>
							Balıkçılık
						</Checkbox>
					</Col>
					<Col span={3}>
						<Checkbox value="13" style={{ lineHeight: '32px' }}>
							Ücretli
						</Checkbox>
					</Col>
					<Col span={3}>
						<Checkbox value="14" style={{ lineHeight: '32px' }}>
							Trekking
						</Checkbox>
					</Col>
					<Col span={3}>
						<Checkbox value="15" style={{ lineHeight: '32px' }}>
							Odun
						</Checkbox>
					</Col>
				</Row>
			</Checkbox.Group>
		</Form.Item>
	);
};

export default Facilities;
