import { useState, useEffect } from 'react';
import {
	Form,
	Input,
	Col,
	Row,
	Breadcrumb,
	PageHeader,
	Button,
	Select,
	message,
} from 'antd';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';

import UploadImage from 'src/components/UploadImage';
import Loading from 'src/components/Loading';
import {
	getMarketingMaterialRequest,
	updateMarketingMaterialRequest,
} from '../network';

const { Option } = Select;

const tailLayout = {
	wrapperCol: { offset: 0, span: 24 },
};

const layout = {
	labelCol: { span: 3 },
	wrapperCol: { span: 22 },
};

interface MatchParams {
	marketingId: string;
}

const FeedbackUpdateScreen = () => {
	let history = useHistory();
	const [form] = Form.useForm();
	let { params } = useRouteMatch<MatchParams>();

	const [marketingData, setMarketingData] = useState<any>({});
	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(true);
	const [uploadedImageData, setUploadedImageData] = useState<any>([]);

	useEffect(() => {
		getMarketing();
	}, []);

	const getMarketing = async () => {
		const response: any = await getMarketingMaterialRequest(params.marketingId);
		const images: any = [];
		if (response.status) {
			setMarketingData(response.data);
			images.push({
				url: response.data?.image,
				status: 'done',
				uid: `img_${1}`,
			});

			setImages(images);
			setUploadedImageData(images);
			form.setFieldsValue(response.data);
			setLoading(false);
		}
	};

	const onUploadChange = ({ uploadedImages, fileList }: any) => {
		setUploadedImageData(fileList);
	};

	const onThumbnailChange = (url: string) => {
		console.log('thumbnail', url);
	};

	const onFinish = async (values: any) => {
		values.image = uploadedImageData?.[0]?.url || values.image;
		const params = { ...marketingData, ...values };
		delete params.imagePaths;
		if (values.image) {
			const response = await updateMarketingMaterialRequest(params);
			if (response.status) {
				history.goBack();
			}
		} else {
			message.error('Image Url cant be null');
		}
	};

	return (
		<Row>
			<Col span={24} style={{ paddingTop: 20 }}>
				<Breadcrumb>
					<Breadcrumb.Item>
						<Link to={'/marketing'}>Marketing</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>{marketingData.title}</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title="Marketing Info"
				/>
			</Col>

			<Col span={24} style={{ marginTop: '20px' }}>
				<Loading loading={false}>
					<Form
						size={'large'}
						{...layout}
						form={form}
						name="control-ref"
						scrollToFirstError={true}
						onFinish={onFinish}
					>
						{!loading && (
							<>
								<Form.Item name={'imagePaths'} label={'Images'}>
									<UploadImage
										onChange={onUploadChange}
										imageData={images}
										bucketName={''}
										onThumbnailChange={onThumbnailChange}
									/>
								</Form.Item>
								<Form.Item
									name={'name'}
									label={'Title'}
									rules={[{ required: true }]}
								>
									<Input />
								</Form.Item>
								<Form.Item name={'image'} label={'Image Url'}>
									<Input />
								</Form.Item>
								<Form.Item
									name={'url'}
									label={'Link Url'}
									rules={[{ required: true }]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									labelCol={{ span: 3 }}
									name={'category'}
									label={'Kategori'}
									rules={[{ required: true }]}
								>
									<Select>
										<Option value={'tent'}>Çadır</Option>
										<Option value={'sleeping-bag'}>Uyku Tulumu</Option>
										<Option value={'mat'}>Mat</Option>
										<Option value={'kitchen'}>Mutfak Eşyaları</Option>
									</Select>
								</Form.Item>
								<Form.Item {...tailLayout} style={{ textAlign: 'right' }}>
									<Button
										type="primary"
										htmlType="submit"
										style={{ width: '20%' }}
									>
										Güncelle
									</Button>
								</Form.Item>
							</>
						)}
					</Form>
				</Loading>
			</Col>
		</Row>
	);
};

export default FeedbackUpdateScreen;
