import { createStore, applyMiddleware } from 'redux';

import thunk from 'redux-thunk';
import { createPromise } from 'redux-promise-middleware';
// import { createLogger } from 'redux-logger';
// import { persistStore, persistReducer } from 'redux-persist';
// import AsyncStorage from '@react-native-community/async-storage';

import { composeWithDevTools } from 'redux-devtools-extension';

import { rootReducer } from './ducks/index';

const promiseMiddleware = createPromise({ promiseTypeDelimiter: '@' });
const middlewares = [thunk, promiseMiddleware];

// const persistedReducer = persistReducer(persistConfig, ducks);

let store = createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(...middlewares)),
);
// let persistor = persistStore(store);

export default store;
// export { persistor };
