import { Row, Col } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Loading from 'src/components/Loading';
import BlogListScreen from './Sections/BlogList.screen';

import './index.scss';
import BlogCreateScreen from './Sections/BlogCreate.screen';
import BlogUpdateScreen from './Sections/BlogUpdate.screen';

const BlogScreen = () => {
	let { path } = useRouteMatch();
	const loading = false;

	return (
		<Loading loading={loading}>
			<Row>
				<Col
					span={24}
					style={{
						width: '100%',
						display: 'block',
						textAlign: 'left',
						backgroundColor: 'white',
						padding: '0px 20px',
					}}
				>
					<Switch>
						<Route exact path={path} component={BlogListScreen} />
						<Route exact path={`${path}/create`} component={BlogCreateScreen} />
						<Route
							exact
							path={`${path}/update/:blogId`}
							component={BlogUpdateScreen}
						/>
					</Switch>
				</Col>
			</Row>
		</Loading>
	);
};

export default BlogScreen;
