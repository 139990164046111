import React, { useEffect, useState } from 'react';
import { Layout, Menu, Typography } from 'antd';
import {
	Switch,
	Route,
	Link,
	useLocation,
	useHistory,
	Redirect,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
	MenuFoldOutlined,
	LineChartOutlined,
	LoginOutlined,
	UserOutlined,
	QuestionCircleOutlined,
} from '@ant-design/icons';
import { setUser } from 'src/redux/ducks/user';
import { RootState } from 'src/redux/ducks';
import routes from 'src/config/routes';
import helpers from 'src/utils/helpers';
import { Routes } from 'src/utils/constants';
import TutorialModal from 'src/components/TutorialModal/index';

const { Header, Sider, Content } = Layout;
const { Text } = Typography;

const MainLayout: React.FC = () => {
	const dispatch = useDispatch();
	let location = useLocation();
	let history = useHistory();

	let [selectedKey, setSelectedKey] = useState('home');
	const [visible, setVisible] = useState(false);

	const user = useSelector((state: RootState) => state.user.userData);

	useEffect(() => {
		if (location.pathname) {
			const paths = location.pathname.split('/');
			setSelectedKey(`/${paths[1]}`);
		}
	}, [location]);

	const isRouteAllow = (key: string) => {
		const pageUrl = key.split('/')[1];
		const routeItem = Routes.find((route) => route.key === `/${pageUrl}`);
		if (
			routeItem &&
			(user?.permissions?.includes(routeItem.permission) ||
				user?.role === 'ADMIN')
		) {
			return true;
		}
		return false;
	};

	const RouteWithSubRoutes = (route: any) => {
		return (
			<Route
				path={route.path}
				render={(props) =>
					user ? (
						isRouteAllow(route.path) ||
						route.path === '/' ||
						route.path === '/profile' ? (
							<route.component {...props} routes={route.routes} />
						) : (
							<Redirect
								to={{ pathname: '/', state: { from: props.location } }}
							/>
						)
					) : (
						<Redirect
							to={{ pathname: '/login', state: { from: props.location } }}
						/>
					)
				}
			/>
		);
	};

	const handleMenuClick = (e: any) => {
		setSelectedKey(e.key);
	};

	const goToProfile = () => {
		history.replace('/profile');
	};

	const logout = () => {
		helpers.removeAuthToken();
		helpers.setUserInfo({});
		dispatch(setUser(null));
		history.replace('/login');
	};

	return (
		<Layout style={{ minHeight: '100vh', maxHeight: '100%', height: '100%' }}>
			<Sider trigger={null} collapsible collapsed={false}>
				<div
					style={{ cursor: 'pointer' }}
					className="logo"
					onClick={() => history.replace('/')}
				>
					<img
						src={'https://kampp.in/logo_secondary.png'}
						style={{
							height: '40px',
							margin: '10px 0px',
						}}
						alt={'RBS'}
					/>
				</div>

				<Menu
					theme="dark"
					mode="inline"
					defaultOpenKeys={['/']}
					selectedKeys={[selectedKey]}
					onClick={handleMenuClick}
				>
					<Menu.Item key={'/'} icon={<LineChartOutlined />}>
						<Link to={'/'}>Genel Bakış</Link>
					</Menu.Item>
					{Routes.map((route) => {
						if (
							user?.permissions?.includes(route.permission) ||
							user?.role === 'ADMIN'
						) {
							return (
								<Menu.Item key={route.key} icon={route.icon}>
									<Link to={route.key}>{route.title}</Link>
								</Menu.Item>
							);
						}
						return null;
					})}
				</Menu>
			</Sider>
			<Layout className="site-layout">
				<Header
					className="site-layout-background"
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: '0px 20px',
					}}
				>
					{React.createElement(
						MenuFoldOutlined,
						// {
						// 	className: 'trigger',
						// 	onClick: () => setCollapsed(!collapsed),
						// 	style: { color: 'white' },
						// },
					)}
					<div style={{ display: 'flex' }}>
						<div onClick={goToProfile} style={{ marginRight: '20px' }}>
							<Text
								style={{ color: 'white', cursor: 'pointer' }}
								type="secondary"
							>
								<UserOutlined /> {user.fullName}
							</Text>
						</div>
						<div
							onClick={() => setVisible(true)}
							style={{ marginRight: '20px' }}
						>
							<Text
								style={{ color: 'white', cursor: 'pointer' }}
								type="secondary"
							>
								<QuestionCircleOutlined /> Nasıl kullanırım?
							</Text>
						</div>
						<div onClick={logout}>
							<Text
								style={{ color: 'white', cursor: 'pointer' }}
								type="secondary"
							>
								<LoginOutlined /> Çıkış Yap
							</Text>
						</div>
					</div>
				</Header>
				<Content
					className="site-layout-background"
					style={{
						margin: '24px 16px',
						minHeight: 280,
					}}
				>
					<TutorialModal visible={visible} setVisible={setVisible} />
					<Switch>
						{routes.map((route, i) => (
							<RouteWithSubRoutes key={i} {...route} />
						))}
					</Switch>
				</Content>
			</Layout>
		</Layout>
	);
};

export default MainLayout;
