import React from 'react';
import { Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

// let searchText = '';
// let searchedColumn = '';

const getColumnSearchProps = (dataIndex: any) => ({
	filterDropdown: ({
		setSelectedKeys,
		selectedKeys,
		confirm,
		clearFilters,
	}: any) => (
		<div style={{ padding: 8 }}>
			<Input
				placeholder={`Search ${dataIndex}`}
				value={selectedKeys[0]}
				onChange={(e) =>
					setSelectedKeys(e.target.value ? [e.target.value] : [])
				}
				onPressEnter={() => {
					confirm();
					// searchText = selectedKeys[0];
					// searchedColumn = dataIndex;
				}}
				style={{ width: 188, marginBottom: 8, display: 'block' }}
			/>
			<Space>
				<Button
					type="primary"
					onClick={() => {
						confirm();
						// searchText = selectedKeys[0];
						// searchedColumn = dataIndex;
					}}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90 }}
				>
					Search
				</Button>
				<Button
					onClick={() => clearFilters()}
					size="small"
					style={{ width: 90 }}
				>
					Reset
				</Button>
			</Space>
		</div>
	),
	filterIcon: (filtered: any) => (
		<SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
	),
	onFilter: (value: any, record: any) =>
		record[dataIndex]
			? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
			: '',
	onFilterDropdownVisibleChange: (visible: any) => {
		if (visible) {
			// setTimeout(() => searchInput.select(), 100);
		}
	},
	render: (text: string) => text,
	// searchedColumn === dataIndex ? (
	// 	<Highlighter
	// 		highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
	// 		searchWords={[searchText]}
	// 		autoEscape
	// 		textToHighlight={text ? text.toString() : ''}
	// 	/>
	// ) : (
	// 	text
	// ),
});

export default getColumnSearchProps;
