import {
	DatabaseOutlined,
	TeamOutlined,
	CommentOutlined,
	MessageOutlined,
	PictureOutlined,
	ReadOutlined,
	ProfileOutlined,
} from '@ant-design/icons';

export const customResizeConfigs = [
	{ guid: 'small', width: 256, height: 256, quality: 90 },
	{ guid: 'medium', width: 512, height: 512, quality: 90 },
	{ guid: 'large', width: 1024, height: 1024, quality: 90 },
];

export const Routes = [
	{
		key: '/camp',
		icon: <DatabaseOutlined />,
		title: 'Kamp Alanları',
		permission: 'CAMP',
	},
	{
		key: '/cms',
		icon: <ProfileOutlined />,
		title: 'Cms',
		permission: 'CMS',
	},
	{
		key: '/blog',
		icon: <ReadOutlined />,
		title: 'Makaleler',
		permission: 'BLOG',
	},
	{
		key: '/event',
		icon: <PictureOutlined />,
		title: 'Etkinlikler',
		permission: 'EVENT',
	},
	{
		key: '/feedback',
		icon: <MessageOutlined />,
		title: 'Geri Bildirimler',
		permission: 'FEEDBACK',
	},
	{
		key: '/marketing',
		icon: <MessageOutlined />,
		title: 'Marketing',
		permission: 'MARKETING',
	},
	{
		key: '/comment',
		icon: <CommentOutlined />,
		title: 'Yorumlar',
		permission: 'COMMENTS',
	},
	{
		key: '/users',
		icon: <TeamOutlined />,
		title: 'Kullanıcılar',
		permission: 'USERS',
	},
	{
		key: '/business-users',
		icon: <TeamOutlined />,
		title: 'Partner Kullanıcılar',
		permission: 'PARTNER_USERS',
	},
];
