import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
import AuthLayout from 'src/layouts/AuthLayout';
import Loading from 'src/components/Loading';
import { setUser } from 'src/redux/ducks/user';
import helpers from 'src/utils/helpers';
import { RootState } from 'src/redux/ducks';
import './App.css';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const App = () => {
	let history = useHistory();
	let query = useQuery();

	const dispatch = useDispatch();

	const user = useSelector((state: RootState) => state.user.userData);
	const appLoading = useSelector((state: RootState) => state.app.loading);
	const storageUser = helpers.getUserInfo();

	useEffect(() => {
		const getUserToken = helpers.getAuthToken();
		const getLanguage = helpers.getAppLanguage();
		if (!getLanguage) {
			helpers.setAppLanguage('tr');
		}

		let redirectUrl = '/login';

		if (query.get('projectId')) {
			redirectUrl = `/login?projectId=${query.get('projectId')}`;
		}

		if (!getUserToken) {
			history.replace(redirectUrl);
		} else {
			dispatch(setUser(storageUser));
		}
	}, [dispatch, history]);

	return (
		<div className="App">
			<Loading loading={appLoading} fullscreen>
				{user ? <MainLayout /> : <AuthLayout />}
			</Loading>
		</div>
	);
};

export default App;
