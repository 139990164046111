import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, PageHeader, Table, AutoComplete, Input } from 'antd';
import { useRouteMatch, useHistory, useLocation, Link } from 'react-router-dom';
import { RootState } from 'src/redux/ducks';
import getColumnSearchProps from 'src/components/SearchableColumn';
import { getCampList } from 'src/redux/ducks/camp';
import { searchCampRequest } from '../network';

const CampListScreen = () => {
	const dispatch = useDispatch();
	let { url } = useRouteMatch();
	let history = useHistory();
	let location = useLocation();
	let [currentPage, setCurrentPage] = useState<number>(1);
	const { camps, count, loading } = useSelector(
		(state: RootState) => state.camp,
	);

	const user = useSelector((state: RootState) => state.user.userData);

	const [options, setOptions] = useState<any>([]);

	const columns: any = [
		{
			title: 'Thumbnail',
			dataIndex: 'thumbnail',
			render: (_: number, record: any) => {
				return (
					<img src={record.thumbnail} alt="thumbnail" width={75} height={75} />
				);
			},
		},
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			...getColumnSearchProps('id'),
			sorter: {
				compare: (a: any, b: any) => a.id - b.id,
			},
			render: (_: number, record: any) => {
				return (
					<Link style={{ marginRight: 15 }} to={`${url}/update/${record.id}`}>
						{record.id}
					</Link>
				);
			},
		},
		{
			title: 'İSİM',
			dataIndex: 'title',
			key: 'title',
			...getColumnSearchProps('title'),
			sorter: (a: any, b: any) => {
				return a.title?.localeCompare(b.title);
			},
		},
		{
			title: 'KONUM',
			dataIndex: 'city',
			render: (_: number, record: any) => {
				return `${record.city} / ${record.county}`;
			},
		},
		// {
		// 	title: 'Operation',
		// 	dataIndex: 'Operation',
		// 	editable: false,
		// 	fixed: 'right',
		// 	width: 150,
		// 	render: (_: undefined, record: any) => (
		// 		<Popconfirm
		// 			title="Sure to delete?"
		// 			onConfirm={() => deleteCampRecord(record.id)}
		// 		>
		// 			<span style={{ color: '#1890ff', cursor: 'pointer' }}>Delete</span>
		// 		</Popconfirm>
		// 	),
		// },
	];

	useEffect(() => {
		const getPage = location.search.split('=')[1];
		if (getPage) {
			setCurrentPage(parseInt(getPage));
			dispatch(getCampList(getPage));
		} else {
			dispatch(getCampList(currentPage));
		}
	}, []);

	const onTableChange = (pagination: any, filters: any, sorter: any) => {
		let _page = pagination.current;
		if (currentPage !== pagination.current) {
			window.history.replaceState(
				null,
				'',
				`/camp/?page=${pagination.current}`,
			);
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}

		setCurrentPage(pagination.current);
		dispatch(getCampList(_page));
	};

	const onSelect = async (value: string) => {
		if (value) {
			history.push(`${url}/update/${value}`);
		}
	};

	const handleSearch = async (value: string) => {
		if (value.length > 2) {
			const response = await searchCampRequest(value);
			if (response.camp.length > 0) {
				response.camp.forEach((camp: any) => {
					camp['value'] = camp.id;
					camp['label'] = camp.title;
				});
				setOptions(response.camp);
			}
		}
	};

	const deleteCampRecord = (campId: string) => {
		//
	};

	return (
		<Row>
			{user?.role === 'ADMIN' && (
				<Col span={24}>
					<PageHeader
						className="site-page-header no-left-padding"
						title={count && `${count} Kamp Alanı`}
					/>
				</Col>
			)}

			{user?.role === 'ADMIN' && (
				<Col span={24}>
					<AutoComplete
						dropdownMatchSelectWidth={252}
						style={{ width: 300 }}
						options={options}
						onSelect={onSelect}
						onSearch={handleSearch}
					>
						<Input.Search
							size="large"
							placeholder="Kamp Alanı Ara"
							enterButton
						/>
					</AutoComplete>
				</Col>
			)}

			<Col span={24} style={{ marginTop: '15px' }}>
				<Table
					dataSource={camps}
					columns={columns}
					loading={loading}
					onChange={onTableChange}
					pagination={{
						current: currentPage,
						pageSize: 20,
						total: count,
					}}
				/>
			</Col>
		</Row>
	);
};

export default CampListScreen;
