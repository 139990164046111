import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Upload, Modal, Tooltip, message, Button } from 'antd';
import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { PlusOutlined } from '@ant-design/icons';
import {
	imageSizeControl,
	imageFileSelector,
} from 'src/utils/utilityFunctions';
import {
	uploadImageRequest,
	uploadThumbnailRequest,
} from 'src/containers/Camp/network';

function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

const type = 'DragableUploadList';
const RNDContext = createDndContext(HTML5Backend);

const uploadButton = (
	<div>
		<PlusOutlined />
		<div style={{ marginTop: 8 }}>Upload</div>
	</div>
);

const DragableUploadListItem = ({ originNode, moveRow, file, fileList }) => {
	const ref = React.useRef();
	const index = fileList.indexOf(file);
	const [{ isOver, dropClassName }, drop] = useDrop({
		accept: type,
		collect: (monitor) => {
			const { index: dragIndex } = monitor.getItem() || {};
			if (dragIndex === index) {
				return {};
			}
			return {
				isOver: monitor.isOver(),
				dropClassName:
					dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
			};
		},
		drop: (item) => {
			moveRow(item.index, index);
		},
	});
	const [, drag] = useDrag({
		item: { type, index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});
	drop(drag(ref));
	const errorNode = (
		<Tooltip title="Upload Error" getPopupContainer={() => document.body}>
			{originNode.props.children}
		</Tooltip>
	);
	return (
		<div
			ref={ref}
			className={`ant-upload-draggable-list-item ${
				isOver ? dropClassName : ''
			}`}
			style={{ cursor: 'move' }}
		>
			{file.status === 'error' ? errorNode : originNode}
		</div>
	);
};

const UploadImage = ({
	onChange,
	onUploadChange = null,
	onThumbnailChange = {},
	imageData,
	single = false,
	customResizeConfigs = null,
	namePrefix = '',
	bucketName = '',
}) => {
	let [previewImage, setPreviewImage] = useState('');
	let [previewVisible, setPreviewVisible] = useState(false);
	let [previewTitle, setPreviewTitle] = useState('');
	const [fileList, setFileList] = useState(imageData);
	const [uploadedImages, setUploadedImages] = useState(imageData);

	const configData = {};

	useEffect(() => {
		const imageObject = {
			uploadedImages,
			fileList,
		};
		onChange(imageObject);
	}, [uploadedImages, fileList]);

	useEffect(() => {
		if (single) {
			setFileList(imageData);
			// setUploadedImages(imageData);
		}
	}, [imageData, single]);

	const manager = useRef(RNDContext);

	const moveRow = useCallback(
		(dragIndex, hoverIndex) => {
			const dragRow = fileList[dragIndex];
			setFileList(
				update(fileList, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragRow],
					],
				}),
			);
		},
		[fileList],
	);

	const handleCancel = () => {
		setPreviewVisible(false);
	};

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		setPreviewImage(file.url || file.preview);
		setPreviewVisible(true);
		setPreviewTitle(
			file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
		);
	};

	const handleChange = ({ file, fileList }) => {
		setFileList(fileList.filter((file) => !!file.status));
	};

	const customRequest = async ({ onSuccess, onError, file }) => {
		if (imageSizeControl(file)) {
			const resizeConfigs = customResizeConfigs
				? customResizeConfigs
				: configData.resizeConfigs;

			const params = await imageFileSelector(
				file,
				resizeConfigs,
				false,
				namePrefix,
			);

			const datam = {
				fileName: params.data,
				title: params.alias,
				type: params.contentType,
				bucketName: bucketName,
			};

			const response = await uploadImageRequest(datam);
			if (response.status) {
				onSuccess(null, file);
				const filteredFilesList = fileList.filter(
					(file) => file.status !== 'uploading',
				);
				const imageData = [...uploadedImages];
				const imageObject = {
					url: response.Location,
					status: 'done',
					uid: `img_${response.Location}`,
				};
				imageData.push(response.Location);
				setUploadedImages(imageData);
				setFileList([...filteredFilesList, imageObject]);
			} else {
				onError(null, file);
			}
		} else {
			onError(null, file);
		}
	};

	const makeThumbnail = async () => {
		const response = await uploadThumbnailRequest(previewImage);
		if (response.status) {
			onThumbnailChange(response.Location);
			return message.success('Basarili');
		}
		message.error('Hata');
	};

	const props = {
		name: 'file',
		listType: 'picture-card',
		fileList: fileList,
		disabled: !bucketName,
		customRequest: customRequest,
		onChange(info) {
			handleChange(info);

			if (info.file.status === 'removed' && single) {
				setUploadedImages([]);
				onUploadChange && onUploadChange([]);
			}

			if (info.file.status === 'done') {
				message.success(`${info.file.name} file uploaded successfully`);
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
		onPreview: handlePreview,
		itemRender(originNode, file, currFileList) {
			return (
				<DragableUploadListItem
					originNode={originNode}
					file={file}
					fileList={currFileList}
					moveRow={moveRow}
				/>
			);
		},
	};

	return (
		<>
			<DndProvider manager={manager.current.dragDropManager}>
				{/* <ImgCrop quality={0.8} grid rotate> */}
				<Upload {...props}>{uploadButton}</Upload>
				{/* </ImgCrop> */}
			</DndProvider>
			<Modal
				visible={previewVisible}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}
			>
				<img alt="example" style={{ width: '100%' }} src={previewImage} />
				<Button onClick={makeThumbnail}>Make Thumbnail</Button>
			</Modal>
		</>
	);
};

export default UploadImage;
