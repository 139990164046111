import { Row, Col } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Loading from 'src/components/Loading';
import CmsListScreen from './Sections/CmsList.screen';
import CmsCreateScreen from './Sections/CmsCreate.screen';
import CmsUpdateScreen from './Sections/CmsUpdate.screen';

const CmsScreen = () => {
	let { path } = useRouteMatch();
	const loading = false;

	return (
		<Loading loading={loading}>
			<Row>
				<Col
					span={24}
					style={{
						width: '100%',
						display: 'block',
						textAlign: 'left',
						backgroundColor: 'white',
						padding: '0px 20px',
					}}
				>
					<Switch>
						<Route exact path={path} component={CmsListScreen} />
						<Route exact path={`${path}/create`} component={CmsCreateScreen} />
						<Route
							exact
							path={`${path}/update/:cmsId`}
							component={CmsUpdateScreen}
						/>
					</Switch>
				</Col>
			</Row>
		</Loading>
	);
};

export default CmsScreen;
