import { useState, useEffect } from 'react';
import {
	Form,
	Input,
	Button,
	Col,
	Row,
	Breadcrumb,
	PageHeader,
	Popconfirm,
	Table,
	AutoComplete,
	Switch,
	Select,
	message,
} from 'antd';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import {
	SortableContainer,
	SortableElement,
	SortableHandle,
} from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';

import { searchCampRequest } from 'src/containers/Camp/network';
import getColumnSearchProps from 'src/components/SearchableColumn';
import UploadImage from 'src/components/UploadImage';
import Loading from 'src/components/Loading';
import { getCmsRequest, updateCmsRequest } from '../network';

const { TextArea } = Input;
const { Option } = Select;

const layout = {
	labelCol: { span: 3 },
	wrapperCol: { span: 22 },
};
const tailLayout = {
	wrapperCol: { offset: 0, span: 24 },
};

interface MatchParams {
	cmsId: string;
}

const CmsUpdateScreen = () => {
	let history = useHistory();
	const [form] = Form.useForm();
	let { params } = useRouteMatch<MatchParams>();

	const [cmsData, setCmsData] = useState<any>({});
	const [options, setOptions] = useState<any>([]);
	const [bucketName] = useState('.Explore');
	const [uploadedImageData, setUploadedImageData] = useState<any>([]);
	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(true);
	const [isExternal, setIsExternal] = useState(true);

	const DragHandle = SortableHandle(() => (
		<MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
	));
	const SortableItem = SortableElement((props: any) => <tr {...props} />);
	const SortableBody = SortableContainer((props: any) => <tbody {...props} />);

	const columns: any = [
		{
			title: 'Sort',
			dataIndex: 'sort',
			width: 30,
			className: 'drag-visible',
			render: () => <DragHandle />,
		},
		{
			title: 'Thumbnail',
			dataIndex: 'thumbnail',
			render: (_: number, record: any) => {
				return (
					<img src={record.thumbnail} alt="thumbnail" width={75} height={75} />
				);
			},
		},
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			...getColumnSearchProps('title'),
			sorter: (a: any, b: any) => {
				return a.title?.localeCompare(b.title);
			},
			render: (_: number, record: any) => {
				return (
					<Link style={{ marginRight: 15 }} to={`camp/update/${record.id}`}>
						{record.title}
					</Link>
				);
			},
		},
		{
			title: 'Location',
			dataIndex: 'city',
			render: (_: number, record: any) => {
				return `${record.city} / ${record.county}`;
			},
		},
		{
			title: 'Operation',
			dataIndex: 'Operation',
			editable: false,
			fixed: 'right',
			width: 150,
			render: (_: undefined, record: any) => (
				<Popconfirm
					title="Sure to delete?"
					onConfirm={() => onDelete(record.id)}
				>
					<span style={{ color: '#1890ff', cursor: 'pointer' }}>Delete</span>
				</Popconfirm>
			),
		},
	];

	useEffect(() => {
		getCms();
	}, []);

	const getCms = async () => {
		const response: any = await getCmsRequest(params.cmsId);
		const images: any = [];
		if (response.status) {
			setCmsData(response.data);
			images.push({
				url: response.data.image,
				status: 'done',
				uid: `img_${0}`,
			});
			setImages(images);
			setUploadedImageData(images);
			setIsExternal(response.data.isExternal);
			form.setFieldsValue(response.data);
			setLoading(false);
		}
	};

	const onUploadChange = ({ uploadedImages, fileList }: any) => {
		setUploadedImageData(fileList);
	};

	const onThumbnailChange = (url: string) => {
		console.log('thumbnail', url);
	};

	const handleSearch = async (value: string) => {
		if (value.length > 2) {
			const response = await searchCampRequest(value);
			if (response.camp.length > 0) {
				response.camp.forEach((camp: any) => {
					camp['value'] = camp.id;
					camp['label'] = camp.title;
				});
				setOptions(response.camp);
			}
		}
	};

	const onDelete = (id: any) => {
		const copiedCmsData = { ...cmsData };
		const copiedData = [...copiedCmsData.camp];
		const filteredData = copiedData.filter((camp: any) => camp.id !== id);
		copiedCmsData.camp = filteredData;

		setCmsData(copiedCmsData);
	};

	const onSelect = async (value: string) => {
		if (value) {
			const findCampItem = options.find((camp: any) => camp.id === value);
			if (findCampItem) {
				const copiedCmsData = { ...cmsData };
				copiedCmsData.camp.push(findCampItem);
				console.log('copiedCmsData', copiedCmsData);
				setCmsData(copiedCmsData);
			}
			// history.push(`${url}/update/${value}`);
		}
	};

	const onSortEnd = ({ oldIndex, newIndex }: any) => {
		const { camp } = cmsData;
		if (oldIndex !== newIndex) {
			const newData = arrayMoveImmutable(
				[].concat(camp),
				oldIndex,
				newIndex,
			).filter((el) => !!el);
			setCmsData({ ...cmsData, camp: newData });
		}
	};

	const DraggableContainer = (props: any) => (
		<SortableBody
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			onSortEnd={onSortEnd}
			{...props}
		/>
	);

	const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
		const { camp } = cmsData;
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = camp.findIndex(
			(x: any) => x.id === restProps['data-row-key'],
		);
		return <SortableItem index={index} {...restProps} />;
	};

	const onFinish = async (values: any) => {
		values.image = uploadedImageData?.[0].url;
		delete values.addCamp;
		delete values.exploreCamps;
		delete values.imagePaths;

		const params = { ...cmsData, ...values };
		const response = await updateCmsRequest(params);
		if (response.status) {
			message.success('Cms content successfully updated');
			return history.goBack();
		}
		message.success('Something happened');
	};

	return (
		<Row>
			<Col span={24} style={{ paddingTop: 20 }}>
				<Breadcrumb>
					<Breadcrumb.Item>
						<Link to={'/cms'}>Cms List</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>{cmsData.title}</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title={cmsData.title}
					onBack={() => history.goBack()}
				/>
			</Col>

			<Col span={24} style={{ marginTop: '20px' }}>
				<Loading loading={false}>
					<Form
						size={'large'}
						{...layout}
						form={form}
						name="control-ref"
						scrollToFirstError={true}
						onFinish={onFinish}
					>
						{!loading && (
							<>
								<Form.Item
									name={'imagePaths'}
									label={'Images'}
									rules={[{ required: true }]}
								>
									<UploadImage
										onChange={onUploadChange}
										imageData={images}
										bucketName={bucketName}
										onThumbnailChange={onThumbnailChange}
									/>
								</Form.Item>
								<Form.Item
									name={'title'}
									label={'Title'}
									rules={[{ required: true }]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									name={'description'}
									label={'Description'}
									rules={[{ required: true }]}
								>
									<TextArea rows={4} />
								</Form.Item>
								<Form.Item
									name={'type'}
									label={'Card Type'}
									rules={[{ required: true }]}
								>
									<Select>
										<Option value={'featureCard'}>Feature Card</Option>
										<Option value={'swiperCard'}>Swiper Card</Option>
										<Option value={'sliderCard'}>Slider Card</Option>
									</Select>
								</Form.Item>
								<Form.Item
									name={'category'}
									label={'Category'}
									rules={[{ required: true }]}
								>
									<Select>
										<Option value={'camping'}>Camping</Option>
										<Option value={'facility'}>Facility</Option>
										<Option value={'bunglow'}>Bunglows</Option>
										<Option value={'caravan'}>Caravans</Option>
										<Option value={'climbing'}>Climbing</Option>
									</Select>
								</Form.Item>
								<Form.Item
									valuePropName="checked"
									name={'isExternal'}
									label={'Is External '}
								>
									<Switch onChange={setIsExternal} />
								</Form.Item>
								{isExternal && (
									<Row gutter={8}>
										<Col span={12}>
											<Form.Item
												labelCol={{ span: 6 }}
												name={'buttonText'}
												label={'Button Text '}
											>
												<Input />
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												labelCol={{ span: 5 }}
												name={'externalUrl'}
												label={'External Url'}
											>
												<Input />
											</Form.Item>
										</Col>
									</Row>
								)}

								<Form.Item name={'addCamp'} label={'Add Camp Site'}>
									<AutoComplete
										dropdownMatchSelectWidth={252}
										style={{ width: 300 }}
										options={options}
										onSelect={onSelect}
										onSearch={handleSearch}
									>
										<Input.Search
											size="large"
											placeholder="Kamp Alanı Ekle"
											enterButton
										/>
									</AutoComplete>
								</Form.Item>

								<Form.Item name={'exploreCamps'} label={'Camping Sites'}>
									<Table
										rowKey={(row) => row.id}
										dataSource={[...cmsData.camp]}
										columns={columns}
										components={{
											body: {
												wrapper: DraggableContainer,
												row: DraggableBodyRow,
											},
										}}
									/>
								</Form.Item>

								<Form.Item {...tailLayout} style={{ textAlign: 'right' }}>
									<Button
										type="primary"
										htmlType="submit"
										style={{ width: '20%' }}
									>
										Update Content
									</Button>
								</Form.Item>
							</>
						)}
					</Form>
				</Loading>
			</Col>
		</Row>
	);
};

export default CmsUpdateScreen;
