import { useEffect, useState } from 'react';
import { Row, Col, PageHeader, Table, Popconfirm, message, Button } from 'antd';
import { useRouteMatch, Link } from 'react-router-dom';

import getColumnSearchProps from 'src/components/SearchableColumn';
import {
	deleteMarketingMaterialRequest,
	getMarketingMaterialsRequest,
} from '../network';

const MarketingListScreen = () => {
	let { url } = useRouteMatch();

	const [marketingData, setMarketingData] = useState([]);
	const [loading, setLoading] = useState(false);

	const columns: any = [
		{
			title: 'Title',
			dataIndex: 'name',
			...getColumnSearchProps('name'),
			sorter: {
				compare: (a: any, b: any) => a.id - b.id,
			},
			render: (_: number, record: any) => {
				return (
					<Link style={{ marginRight: 15 }} to={`${url}/${record.id}`}>
						{record.name}
					</Link>
				);
			},
		},
		{
			title: 'Url ',
			dataIndex: 'url',
		},
		{
			title: 'Category',
			dataIndex: 'category',
		},
		{
			title: 'Operation',
			dataIndex: 'Operation',
			editable: false,
			fixed: 'right',
			width: 150,
			render: (_: undefined, record: any) => (
				<Popconfirm
					title="Sure to delete?"
					onConfirm={() => deleteMarketing(record.id)}
				>
					<span style={{ color: '#1890ff', cursor: 'pointer' }}>Delete</span>
				</Popconfirm>
			),
		},
	];

	const deleteMarketing = async (feedbackId: string) => {
		const response = await deleteMarketingMaterialRequest(feedbackId);
		if (response.status) {
			await getMarketingMaterials();
			return message.success(`Successfully deleted`);
		}
		message.error(response.responseMessage);
	};

	useEffect(() => {
		getMarketingMaterials();
	}, []);

	const getMarketingMaterials = async () => {
		setLoading(true);
		const resp = await getMarketingMaterialsRequest();
		if (resp.status) {
			setMarketingData(resp.data);
		}
		setLoading(false);
	};

	return (
		<Row>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title={`Marketing Materials`}
					extra={[
						<Button key={1} type={'primary'}>
							<Link to={`${url}/create`}>Yeni Marketing Material Ekle</Link>
						</Button>,
					]}
				/>
			</Col>

			<Col span={24} style={{ marginTop: '15px' }}>
				<Table
					dataSource={marketingData}
					rowKey={(obj: any) => obj.id}
					columns={columns}
					loading={loading}
				/>
			</Col>
		</Row>
	);
};

export default MarketingListScreen;
