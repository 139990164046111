import { request } from 'src/utils/request';

export async function getBlogListRequest(page: number, pageSize: number) {
	const GET_BLOGS = `partnerBlogs?page=${page}&pageSize=${pageSize}`;
	try {
		const result: any = await request(GET_BLOGS, 'GET');
		if (result) {
			return { status: true, ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function getBlogRequest(blogId: string) {
	const GET_BLOG = `partnerBlog/${blogId}`;
	try {
		const result: any = await request(GET_BLOG, 'GET');
		if (result) {
			return { ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function postBlogRequest(data: any) {
	const POST_BLOG = `blog`;
	try {
		const result: any = await request(POST_BLOG, 'POST', data);
		if (result) {
			return { status: true, data: result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function updateBlogRequest(data: any) {
	const POST_BLOG = `blog/${data.id}`;
	try {
		const result: any = await request(POST_BLOG, 'PUT', data);
		if (result) {
			return { status: true, data: result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function deleteBlogRequest(id: string) {
	const POST_BLOG = `blog/${id}`;
	try {
		const result: any = await request(POST_BLOG, 'DELETE');
		if (result) {
			return { status: true, data: result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}
