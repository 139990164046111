import { request } from 'src/utils/request';

export async function getUserListRequest(page: string) {
  const GET_USERS = `getAllUser?page=${page}&pageSize=20`;
	try {
		const result: any = await request(GET_USERS, 'GET');
		if (result.status) {
			return { status: true, ...result.data };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}