/* eslint-disable import/no-anonymous-default-export */
import Home from 'src/containers/Home';
import CampScreen from 'src/containers/Camp/Camp.screen';
import UsersScreen from 'src/containers/Users/Users.screen';
import BlogScreen from 'src/containers/Blog/Blog.screen';
import EventScreen from 'src/containers/Event/Event.screen';
import FeedbackScreen from 'src/containers/Feedback/Feedback.screen';
import CommentScreen from 'src/containers/Comment/Comment.screen';
import CmsScreen from 'src/containers/Cms/Cms.screen';
import BusinessUsersScreen from 'src/containers/BusinessUser/BusinessUsers.screen';
import ProfileScreen from 'src/containers/Profile/Profile.screen';
import MarketingMaterial from 'src/containers/MarketingMaterial/MarketingMaterial';

export default [
	{
		path: '/',
		exact: true,
		component: Home,
	},
	{
		path: '/camp',
		component: CampScreen,
	},
	{
		path: '/cms',
		component: CmsScreen,
	},
	{
		path: '/blog',
		component: BlogScreen,
	},
	{
		path: '/event',
		component: EventScreen,
	},
	{
		path: '/feedback',
		component: FeedbackScreen,
	},
	{
		path: '/marketing',
		component: MarketingMaterial,
	},
	{
		path: '/comment',
		component: CommentScreen,
	},
	{
		path: '/users',
		component: UsersScreen,
	},
	{
		path: '/business-users',
		component: BusinessUsersScreen,
	},
	{
		path: '/profile',
		component: ProfileScreen,
	},
];
