import { useEffect } from 'react';
import { Row, Col, PageHeader, Table, Popconfirm, message } from 'antd';
import { useRouteMatch, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { RootState } from 'src/redux/ducks';
import getColumnSearchProps from 'src/components/SearchableColumn';
import { getFeedbackList, setPage } from 'src/redux/ducks/feedback';
import { deleteFeedbackRequest } from '../network';

const CampListScreen = () => {
	let { url } = useRouteMatch();
	const dispatch = useDispatch();

	const { feedbacks, count, page, loading } = useSelector(
		(state: RootState) => state.feedback,
	);

	const columns: any = [
		{
			title: 'Email',
			dataIndex: 'email',
		},
		{
			title: 'Full Name ',
			dataIndex: 'fullName',
			...getColumnSearchProps('fullName'),
			sorter: {
				compare: (a: any, b: any) => a.id - b.id,
			},
			render: (_: number, record: any) => {
				return (
					<Link style={{ marginRight: 15 }} to={`${url}/${record.id}`}>
						{record.fullName}
					</Link>
				);
			},
		},
		{
			title: 'Feedback Place',
			dataIndex: 'campInformation.title',
			render: (_: number, record: any) => {
				return record?.campInformation?.title;
			},
		},
		{
			title: 'Created',
			dataIndex: 'createdAt',
			render: (_: number, record: any) => {
				return `${moment(record.createdAt).format('DD MMMM YYYY')}`;
			},
		},
		{
			title: 'Operation',
			dataIndex: 'Operation',
			editable: false,
			fixed: 'right',
			width: 150,
			render: (_: undefined, record: any) => (
				<Popconfirm
					title="Sure to delete?"
					onConfirm={() => deleteFeedback(record.id)}
				>
					<span style={{ color: '#1890ff', cursor: 'pointer' }}>Delete</span>
				</Popconfirm>
			),
		},
	];

	const deleteFeedback = async (feedbackId: string) => {
		const response = await deleteFeedbackRequest(feedbackId);
		if (response.status) {
			dispatch(getFeedbackList(page));
			return message.success(`Successfully deleted`);
		}
		message.error(response.responseMessage);
	};

	useEffect(() => {
		if (feedbacks.length < 1) {
			dispatch(getFeedbackList(page));
		}
	}, []);

	const onTableChange = (pagination: any, filters: any, sorter: any) => {
		let _page = pagination.current;
		if (page !== pagination.current) {
			window.history.replaceState(
				null,
				'',
				`/feedback?page=${pagination.current}`,
			);
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}

		dispatch(setPage(pagination.current));
		dispatch(getFeedbackList(_page));
	};

	return (
		<Row>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title={`Feedbacks`}
				/>
			</Col>

			<Col span={24} style={{ marginTop: '15px' }}>
				<Table
					dataSource={feedbacks}
					rowKey={(obj: any) => obj.id}
					columns={columns}
					loading={loading}
					onChange={onTableChange}
					pagination={{
						current: page,
						pageSize: 10,
						total: count,
					}}
				/>
			</Col>
		</Row>
	);
};

export default CampListScreen;
