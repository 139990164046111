import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import Login from 'src/containers/Login';
import Register from 'src/containers/Register';

const AuthLayout: React.FC = (props) => {
	return (
		<Layout>
			<Switch>
				<Route exact path="/login">
					<Login />
				</Route>
				<Route path="/register">
					<Register />
				</Route>
			</Switch>
		</Layout>
	);
};

export default AuthLayout;
