import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, PageHeader, Table, Popconfirm } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { RootState } from 'src/redux/ducks';
import getColumnSearchProps from 'src/components/SearchableColumn';
import { getUserCampList, setUserPage } from 'src/redux/ducks/camp';
import { deleteUserCampPlaceRequest } from 'src/containers/Camp/network';
import Loading from 'src/components/Loading';

const CampUserListScreen = () => {
	let { url } = useRouteMatch();
	const dispatch = useDispatch();

	const { userCamps, userCount, userLoading, userPage } = useSelector(
		(state: RootState) => state.camp,
	);

	const columns: any = [
		{
			title: 'Thumbnail',
			dataIndex: 'thumbnail',
			render: (_: number, record: any) => {
				return (
					<img
						src={record.imagePaths[0]}
						alt="thumbnail"
						width={75}
						height={75}
					/>
				);
			},
		},
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			...getColumnSearchProps('id'),
			sorter: (a: any, b: any) => {
				return a.id?.localeCompare(b.id);
			},
			render: (_: number, record: any) => {
				return (
					<Link
						style={{ marginRight: 15 }}
						to={`${url}/userUpdate/${record.id}`}
					>
						{record.id}
					</Link>
				);
			},
		},
		{
			title: 'İSİM',
			dataIndex: 'title',
			key: 'title',
			...getColumnSearchProps('title'),
		},
		{
			title: 'KONUM',
			dataIndex: 'city',
			render: (_: number, record: any) => {
				return `${record.city} / ${record.county}`;
			},
		},
		{
			title: 'Operation',
			dataIndex: 'Operation',
			editable: false,
			fixed: 'right',
			width: 150,
			render: (_: undefined, record: any) => (
				<Popconfirm
					title="Sure to delete?"
					onConfirm={() => deleteUserCampPlace(record.id)}
				>
					<span style={{ color: '#1890ff', cursor: 'pointer' }}>Delete</span>
				</Popconfirm>
			),
		},
	];

	useEffect(() => {
		userCamps.length < 1 && dispatch(getUserCampList(userPage));
	}, []);

	const deleteUserCampPlace = async (id: string) => {
		const response = await deleteUserCampPlaceRequest(id);
		if (response.status) {
			dispatch(getUserCampList(userPage));
		}
	};

	const onTableChange = (pagination: any, filters: any, sorter: any) => {
		let _page = pagination.current;
		if (userPage !== pagination.current) {
			window.history.replaceState(
				null,
				'',
				`/camp/userCampSites?page=${pagination.current}`,
			);
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}

		dispatch(setUserPage(pagination.current));
		dispatch(getUserCampList(_page));
	};

	return (
		<Loading loading={userLoading}>
			<Row>
				<Col span={24}>
					<PageHeader
						className="site-page-header no-left-padding"
						title={userCount && `${userCount} Kullanıcı Kamp Alanı`}
					/>
				</Col>

				<Col span={24} style={{ marginTop: '15px' }}>
					<Table
						rowKey={(row) => row.id}
						style={{ minHeight: '65vh' }}
						dataSource={userCamps}
						bordered
						columns={columns}
						loading={userLoading}
						onChange={onTableChange}
						pagination={{
							current: userPage,
							pageSize: 5,
							total: userCount,
						}}
					/>
				</Col>
			</Row>
		</Loading>
	);
};

export default CampUserListScreen;
