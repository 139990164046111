const TOKEN_KEY = '@AuthToken';
const USER_INFO = '@UserInfo';
const REFRESH_TOKEN_KEY = '@RefreshToken';
const APP_LANGUAGE = '@AppLanguage';

const helpers = {
	getAuthToken: () => localStorage.getItem(TOKEN_KEY),
	setAuthToken: (token) => localStorage.setItem(TOKEN_KEY, token),

	getUserInfo: () => {
		if (localStorage.getItem(USER_INFO)) {
			return JSON.parse(localStorage.getItem(USER_INFO));
		}
	},
	setUserInfo: (info) => {
		if (info) {
			return localStorage.setItem(USER_INFO, JSON.stringify(info));
		}
	},

	getRefreshToken: () => localStorage.getItem(REFRESH_TOKEN_KEY),
	setRefreshToken: (token) => localStorage.setItem(REFRESH_TOKEN_KEY, token),

	removeRefreshToken: () => localStorage.removeItem(REFRESH_TOKEN_KEY),
	removeAuthToken: () => localStorage.removeItem(TOKEN_KEY),

	getAppLanguage: () => localStorage.getItem(APP_LANGUAGE),
	setAppLanguage: (language) => localStorage.setItem(APP_LANGUAGE, language),
};

export default helpers;
