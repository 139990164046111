import React, { useState, useEffect } from 'react';
import {
	Form,
	Input,
	InputNumber,
	Button,
	Col,
	Row,
	Breadcrumb,
	PageHeader,
	Select,
	Checkbox,
} from 'antd';
import { useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import UploadImage from 'src/components/UploadImage';
import Loading from 'src/components/Loading';
import { Cities } from 'src/utils/statics';
import Facilities from 'src/components/Camp/Facilities';
import { getCampRequest, updateCampRecord } from '../network';
import { RootState } from 'src/redux/ducks';
import { getMarketingMaterialsRequest } from '../../MarketingMaterial/network';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
	labelCol: { span: 2 },
	wrapperCol: { span: 22 },
};
const tailLayout = {
	wrapperCol: { offset: 0, span: 24 },
};

interface MatchParams {
	campId: string;
}

const CampUpdateScreen = () => {
	let history = useHistory();
	const [form] = Form.useForm();
	let { params } = useRouteMatch<MatchParams>();

	const user = useSelector((state: RootState) => state.user.userData);

	const [campData, setCampData] = useState<any>({});
	const [uploadedImageData, setUploadedImageData] = useState<any>([]);
	const [bucketName, setBucketName] = useState('');
	const [isFacility, setIsFacility] = useState(false);
	const [thumbnail, setThumbnail] = useState('');
	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(true);

	const [marketingData, setMarketingData] = useState([]);

	useEffect(() => {
		getCamp();
		getMarketingMaterials();
	}, []);

	const getCamp = async () => {
		const response = await getCampRequest(params.campId);
		const images: any = [];
		if (response.status) {
			setCampData(response);
			if (response.imagePaths) {
				setBucketName(response.imagePaths[0].split('com/')[1].split('/')[0]);
				setThumbnail(response.thumbnail);
				setIsFacility(response.isFacility);
			}
			response.imagePaths?.forEach((img: any, index: number) => {
				images.push({
					url: img,
					status: 'done',
					uid: `img_${index}`,
				});
			});
			setImages(images);
			setUploadedImageData(images);
			form.setFieldsValue(response);
			setLoading(false);
		}
	};

	const getMarketingMaterials = async () => {
		const resp = await getMarketingMaterialsRequest();
		if (resp.status) {
			setMarketingData(resp.data);
		}
	};

	const onUploadChange = ({ uploadedImages, fileList }: any) => {
		setUploadedImageData(fileList);
	};
	const onThumbnailChange = (url: string) => {
		setThumbnail(url);
	};

	const onFinish = async (values: any) => {
		values.imagePaths = uploadedImageData.map((image: any) => image.url);
		values.thumbnail = thumbnail;
		values.productList = marketingData.filter((marketing: any) =>
			values.products?.includes(marketing.id),
		);
		delete values.buckets;
		const params = { ...campData, ...values };
		delete params.status;

		const response = await updateCampRecord(params);
		if (response.status) {
			history.goBack();
		}
	};

	return (
		<Row>
			<Col span={24} style={{ paddingTop: 20 }}>
				<Breadcrumb>
					<Breadcrumb.Item>
						<Link to={'/camp'}>Kamp Alanları</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>{campData.title}</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title="Alanı Güncelle"
				/>
			</Col>
			<Col span={24} style={{ marginTop: '20px' }}>
				<Loading loading={false}>
					<Form
						size={'large'}
						{...layout}
						form={form}
						name="control-ref"
						scrollToFirstError={true}
						onFinish={onFinish}
					>
						{!loading && (
							<>
								{user.role === 'ADMIN' && (
									<Form.Item
										name={'buckets'}
										label={'Bucket Name'}
										rules={[{ required: false }]}
									>
										<Input
											defaultValue={bucketName}
											disabled
											onChange={(e) => setBucketName(e.target.value)}
										/>
									</Form.Item>
								)}
								<Form.Item
									name={'imagePaths'}
									label={'Fotoğraflar'}
									rules={[{ required: true }]}
								>
									<UploadImage
										onChange={onUploadChange}
										imageData={images}
										bucketName={bucketName}
										onThumbnailChange={onThumbnailChange}
									/>
								</Form.Item>
							</>
						)}
						<Form.Item
							name={'title'}
							label={'Başlık'}
							rules={[{ required: true }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name={'desc'}
							label={'Açıklama'}
							rules={[{ required: true }]}
						>
							<TextArea rows={4} />
						</Form.Item>
						<Row gutter={8}>
							<Col span={12}>
								<Form.Item
									labelCol={{ span: 4 }}
									name={'city'}
									label={'İl'}
									rules={[{ required: true }]}
								>
									<Select virtual={false} showSearch>
										{Cities.map((city) => (
											<Option value={city}>{city}</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									labelCol={{ span: 3 }}
									name={'county'}
									label={'İlçe'}
									rules={[{ required: true }]}
								>
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={12}>
								<Form.Item
									labelCol={{ span: 4 }}
									name={'region'}
									label={'Bölge'}
									rules={[{ required: true }]}
								>
									<Select>
										<Option value={1}>Ege</Option>
										<Option value={2}>Marmara</Option>
										<Option value={3}>Karadeniz</Option>
										<Option value={4}>Akdeniz</Option>
										<Option value={5}>İç Anadolu</Option>
										<Option value={6}>Doğu Anadolu</Option>
										<Option value={7}>Güneydoğu Anadolu</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									labelCol={{ span: 3 }}
									name={'category'}
									label={'Kategori'}
									rules={[{ required: true }]}
								>
									<Select>
										<Option value={'1'}>Ormanlık</Option>
										<Option value={'2'}>Göl</Option>
										<Option value={'3'}>Sahil</Option>
										<Option value={'4'}>Yayla</Option>
										<Option value={'5'}>Dağlık</Option>
										<Option value={'6'}>Doğal</Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							{user?.role === 'ADMIN' && (
								<Col span={12}>
									<Form.Item
										labelCol={{ span: 4 }}
										name={'placeCategories'}
										label={'Alan Tipi'}
										rules={[{ required: true }]}
									>
										<Select mode="multiple">
											<Option value={'camping'}>Camping</Option>
											<Option value={'facility'}>Facility</Option>
											<Option value={'glamping'}>Glamping</Option>
											<Option value={'bunglow'}>Bunglows</Option>
											<Option value={'caravan'}>Caravans</Option>
											<Option value={'climbing'}>Climbing</Option>
										</Select>
									</Form.Item>
								</Col>
							)}
							{user.role === 'ADMIN' && (
								<Col span={12}>
									<Form.Item
										labelCol={{ span: 4 }}
										name={'products'}
										label={'Ürün Listesi'}
										rules={[{ required: false }]}
									>
										<Select
											mode="multiple"
											placeholder="Seciniz"
											defaultValue={[]}
											filterOption={(input, option) =>
												(option!.children as unknown as string).includes(input)
											}
										>
											{marketingData?.map((marketing: any, index) => (
												<Option key={index} value={marketing.id}>
													{marketing?.name}
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>
							)}
						</Row>
						{user?.role === 'ADMIN' && (
							<Form.Item
								labelCol={{ span: 2 }}
								name={'isFacility'}
								label={'Tesis Mi?'}
								valuePropName="checked"
								rules={[{ required: false }]}
							>
								<Checkbox onChange={(e) => setIsFacility(e.target.checked)} />
							</Form.Item>
						)}
						{isFacility && (
							<Row gutter={8}>
								<Col span={8}>
									<Form.Item
										labelCol={{ span: 12 }}
										name={'phone'}
										label={'Tesis Telefon Numarası'}
										rules={[{ required: true }]}
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										labelCol={{ span: 12 }}
										name={'web'}
										label={'Tesis Websitesi'}
										rules={[{ required: true }]}
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										labelCol={{ span: 12 }}
										name={'socialMedia'}
										label={'Tesis Sosyal Medya Hesabı'}
										rules={[{ required: true }]}
									>
										<Input />
									</Form.Item>
								</Col>
							</Row>
						)}
						<Facilities />
						<Row gutter={8}>
							<Col span={4}>
								<Form.Item
									labelCol={{ span: 12 }}
									name={'lat'}
									label={'Enlem'}
									rules={[{ required: true }]}
								>
									<InputNumber />
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									labelCol={{ span: 7 }}
									name={'long'}
									label={'Boylam'}
									rules={[{ required: true }]}
								>
									<InputNumber />
								</Form.Item>
							</Col>
						</Row>
						<Form.Item {...tailLayout} style={{ textAlign: 'right' }}>
							<Button type="primary" htmlType="submit" style={{ width: '20%' }}>
								Güncelle
							</Button>
						</Form.Item>
					</Form>
				</Loading>
			</Col>
		</Row>
	);
};

export default CampUpdateScreen;
