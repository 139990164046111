import { useState, useEffect } from 'react';
import {
	Form,
	Input,
	Table,
	Col,
	Row,
	Breadcrumb,
	PageHeader,
	Switch,
} from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';

import Loading from 'src/components/Loading';
import { getCommentRequest } from '../network';
import { getCampRequest } from 'src/containers/Camp/network';

const { TextArea } = Input;

const layout = {
	labelCol: { span: 3 },
	wrapperCol: { span: 22 },
};

interface MatchParams {
	commentId: string;
}

const CommentUpdateScreen = () => {
	const [form] = Form.useForm();
	let { params } = useRouteMatch<MatchParams>();

	const [commentData, setCommentData] = useState<any>({});
	const [campData, setCampData] = useState<any>({});
	const [loading, setLoading] = useState(true);

	const columns: any = [
		{
			title: 'Thumbnail',
			dataIndex: 'thumbnail',
			render: (_: number, record: any) => {
				return (
					<img src={record.thumbnail} alt="thumbnail" width={75} height={75} />
				);
			},
		},
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			render: (_: number, record: any) => {
				return (
					<Link style={{ marginRight: 15 }} to={`camp/update/${record.id}`}>
						{record.title}
					</Link>
				);
			},
		},
		{
			title: 'Location',
			dataIndex: 'city',
			render: (_: number, record: any) => {
				return `${record.city} / ${record.county}`;
			},
		},
	];

	useEffect(() => {
		getComment();
	}, []);

	const getComment = async () => {
		const response: any = await getCommentRequest(params.commentId);
		if (response) {
			setCommentData(response);
			getCamp(response.data.campId);
			form.setFieldsValue(response.data);
		}
	};

	const getCamp = async (id: string) => {
		console.log('id', id);
		const response: any = await getCampRequest(id);
		if (response?.id) {
			setCampData(response);
			setLoading(false);
		}
	};

	return (
		<Row>
			<Col span={24} style={{ paddingTop: 20 }}>
				<Breadcrumb>
					<Breadcrumb.Item>
						<Link to={'/comment'}>Comments</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>{commentData.fullName}</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title={commentData.fullName}
				/>
			</Col>

			<Col span={24} style={{ marginTop: '20px' }}>
				<Loading loading={false}>
					<Form
						size={'large'}
						{...layout}
						form={form}
						name="control-ref"
						scrollToFirstError={true}
					>
						{!loading && (
							<>
								<Form.Item name={'userFullName'} label={'Full Name'}>
									<Input disabled />
								</Form.Item>
								<Form.Item name={'comment'} label={'Comment'}>
									<TextArea disabled rows={4} />
								</Form.Item>
								<Form.Item
									valuePropName="checked"
									name={'isApproved'}
									label={'Is Approved'}
								>
									<Switch disabled />
								</Form.Item>
								<Form.Item name={'exploreCamps'} label={'Camping Site'}>
									<Table
										rowKey={(row) => row.id}
										dataSource={[campData]}
										columns={columns}
									/>
								</Form.Item>
							</>
						)}
					</Form>
				</Loading>
			</Col>
		</Row>
	);
};

export default CommentUpdateScreen;
