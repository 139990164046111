import { useState, useEffect } from 'react';
import { Form, Input, Button, Col, Row, PageHeader, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import UploadImage from 'src/components/UploadImage';
import { RootState } from 'src/redux/ducks';
import Loading from 'src/components/Loading';
import { setUser } from 'src/redux/ducks/user';
import helpers from 'src/utils/helpers';

import { getBusinessUserRequest, updateBusinessUserRequest } from './network';

const layout = {
	labelCol: { span: 2 },
	wrapperCol: { span: 22 },
};
const tailLayout = {
	wrapperCol: { offset: 0, span: 24 },
};

const ProfileScreen = () => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	const [userData, setUserData] = useState<any>({});
	const [uploadedImageData, setUploadedImageData] = useState<any>([]);
	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(true);

	const user = useSelector((state: RootState) => state.user.userData);

	useEffect(() => {
		getBusinessUser();
	}, []);

	const getBusinessUser = async () => {
		const response = await getBusinessUserRequest(user.id);
		const images: any = [];
		if (response.status) {
			setUserData(response);
			if (response.profilePicture) {
				images.push({
					url: response.profilePicture,
					status: 'done',
					uid: `img_${0}`,
				});
				setImages(images);
				setUploadedImageData(images);
			}
			form.setFieldsValue(response);
			setLoading(false);
		}
	};

	const onUploadChange = ({ uploadedImages, fileList }: any) => {
		setUploadedImageData(fileList);
	};
	const onThumbnailChange = (url: string) => {
		// setThumbnail(url);
	};

	const onFinish = async (values: any) => {
		values.profilePicture = uploadedImageData?.[0]?.url;
		const params = {
			fullName: values.fullName,
			profilePicture: uploadedImageData?.[0]?.url,
			profileSocialMedia: values.profileSocialMedia,
		};

		const response = await updateBusinessUserRequest(user.id, params);
		if (response.status) {
			notification.success({
				message: 'Başarılı',
				description: 'Bilgileriniz başarılı bir şekilde güncellendi.',
			});
			const response = await getBusinessUserRequest(user.id);
			if (response.status) {
				dispatch(setUser(response));
				helpers.setUserInfo(response);
			}
		} else {
			notification.error({
				message: 'Hata',
				description: 'Bir hata oldu. Daha sonra tekrar deneyin.',
			});
		}
	};

	return (
		<Row>
			<Col
				span={24}
				style={{
					width: '100%',
					display: 'block',
					textAlign: 'left',
					backgroundColor: 'white',
					padding: '0px 20px',
				}}
			>
				<Col span={24}>
					<PageHeader
						className="site-page-header no-left-padding"
						title="Profil Bilgisi"
					/>
				</Col>
				<Col span={24} style={{ marginTop: '20px' }}>
					<Loading loading={false}>
						<Form
							size={'large'}
							{...layout}
							form={form}
							name="control-ref"
							scrollToFirstError={true}
							onFinish={onFinish}
						>
							{!loading && (
								<>
									<Form.Item
										name={'imagePaths'}
										label={'Fotoğraf'}
										rules={[{ required: true }]}
									>
										<UploadImage
											onChange={onUploadChange}
											imageData={images}
											bucketName={'ProfilePictures'}
											onThumbnailChange={onThumbnailChange}
										/>
									</Form.Item>
								</>
							)}
							<Form.Item
								name={'fullName'}
								label={'İsim'}
								rules={[{ required: true }]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name={'email'}
								label={'E-Posta'}
								rules={[{ required: true }]}
							>
								<Input disabled />
							</Form.Item>
							<Form.Item
								name={'profileSocialMedia'}
								label={'Sosyal Medya'}
								rules={[{ required: false }]}
							>
								<Input />
							</Form.Item>
							<Form.Item {...tailLayout} style={{ textAlign: 'right' }}>
								<Button
									type="primary"
									htmlType="submit"
									style={{ width: '20%' }}
								>
									Güncelle
								</Button>
							</Form.Item>
						</Form>
					</Loading>
				</Col>
			</Col>
		</Row>
	);
};

export default ProfileScreen;
