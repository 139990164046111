import typeToReducer, { setTypeDelimiter } from 'type-to-reducer';
import {
	createAction,
	createAsyncAction,
} from 'redux-promise-middleware-actions';
import {
	getCampListRequest,
	getUserCampListRequest,
} from 'src/containers/Camp/network';

setTypeDelimiter('@');

// Actions
const VALUE_CHANGED = 'pim/VALUE_CHANGED';
const SET_TAB_ITEM = 'pim/SET_TAB_ITEM';
const SET_SUB_TAB_ITEM = 'pim/SET_SUB_TAB_ITEM';
const SET_USER_PAGE = 'camp/SET_USER_PAGE';

//Asyns Actions
const GET_CAMPS = 'camp/GET_CAMPS';
const GET_USER_CAMPS = 'pim/GET_USER_CAMPS';
const GET_PRODUCT_DETAIL = 'pim/GET_PRODUCT_DETAIL';
const CLEAR_USER_CAMP_LIST = 'camp/CLEAR_USER_CAMP_LIST';

// Action Creators
export const setTabItem = createAction(SET_TAB_ITEM, (tabItem) => tabItem);
export const setUserPage = createAction(SET_USER_PAGE, (userPage) => userPage);
export const clearUserCampList = createAction(
	CLEAR_USER_CAMP_LIST,
	(userCamps) => userCamps,
);
export const setSubTabItem = createAction(
	SET_SUB_TAB_ITEM,
	(subTabItem) => subTabItem,
);
export const setPimValue = createAction(VALUE_CHANGED, (key, value) => {
	return {
		key: key,
		value: value,
	};
});

// Async Action Creators
export const getCampList = createAsyncAction(GET_CAMPS, async (page) => {
	try {
		const data = await getCampListRequest(page);
		return data;
	} catch (error) {
		console.warn('API :: ' + GET_CAMPS, error);
		throw error;
	}
});

export const getUserCampList = createAsyncAction(
	GET_USER_CAMPS,
	async (page) => {
		try {
			const data = await getUserCampListRequest(page);
			return data;
		} catch (error) {
			console.warn('API :: ' + GET_USER_CAMPS, error);
			throw error;
		}
	},
);

// Reducer
const initialState = {
	loading: true,
	userLoading: true,
	tabItem: '',
	subTabItem: '',
	page: 1,
	userPage: 1,
	camps: [],
	userCamps: [],
	count: 0,
	camp: null,
};

export default typeToReducer(
	{
		[VALUE_CHANGED]: (state, action) => {
			return {
				...state,
				[action.payload.key]: action.payload.value,
			};
		},
		[SET_TAB_ITEM]: (state, action) => {
			return {
				...state,
				tabItem: action.payload,
			};
		},
		[SET_USER_PAGE]: (state, action) => {
			return {
				...state,
				userPage: action.payload,
			};
		},
		[SET_SUB_TAB_ITEM]: (state, action) => ({
			...state,
			subTabItem: action.payload,
		}),
		[CLEAR_USER_CAMP_LIST]: (state, action) => ({
			...state,
			userCamps: [],
		}),
		[GET_CAMPS]: {
			PENDING: (state, action) => {
				return {
					...state,
					loading: true,
				};
			},
			REJECTED: (state, action) => {
				return {
					...state,
					loading: false,
				};
			},
			FULFILLED: (state, action) => {
				return {
					...state,
					loading: false,
					camps: action.payload.campData,
					count: action.payload.count,
				};
			},
		},
		[GET_USER_CAMPS]: {
			PENDING: (state, action) => {
				return {
					...state,
					userLoading: true,
				};
			},
			REJECTED: (state, action) => {
				return {
					...state,
					userLoading: false,
				};
			},
			FULFILLED: (state, action) => {
				return {
					...state,
					userLoading: false,
					userCamps: action.payload.campData,
					userCount: action.payload.count,
				};
			},
		},
		[GET_PRODUCT_DETAIL]: {
			PENDING: (state, action) => {
				return {
					...state,
				};
			},
			REJECTED: (state, action) => {
				return {
					...state,
				};
			},
			FULFILLED: (state, action) => {
				return {
					...state,
					product: action.payload.data,
				};
			},
		},
	},
	initialState,
);
