import { request } from 'src/utils/request';

export async function getCmsListRequest() {
	const GET_CMS_LIST = `explore/list`;
	try {
		const result: any = await request(GET_CMS_LIST, 'GET');
		if (result) {
			return { status: true, data: result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function getCmsRequest(cmsId: string) {
	const GET_CMS = `explore/${cmsId}`;
	try {
		const result: any = await request(GET_CMS, 'GET');
		if (result) {
			return { status: true, data: result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function postCmsRequest(data: any) {
	const UPDATE_CMS = `explore`;
	try {
		const result: any = await request(UPDATE_CMS, 'POST', data);
		if (result) {
			return { status: true, ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function updateCmsRequest(data: any) {
	const UPDATE_CMS = `explore`;
	try {
		const result: any = await request(UPDATE_CMS, 'PUT', data);
		if (result) {
			return { status: true, ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function deleteCmsRequest(id: any) {
	const DELETE_CMS = `explore`;
	try {
		const result: any = await request(DELETE_CMS, 'DELETE', { id });
		if (result) {
			return { status: true, ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}
