import React, { useState, useEffect } from 'react';
import {
	Form,
	Input,
	InputNumber,
	Button,
	Col,
	Row,
	Breadcrumb,
	PageHeader,
	Select,
	Checkbox,
} from 'antd';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import UploadImage from 'src/components/UploadImage';
import Loading from 'src/components/Loading';
import { Cities } from 'src/utils/statics';
import Facilities from 'src/components/Camp/Facilities';
import {
	getUserCampRequest,
	uploadImageRequest,
	postCampRequest,
} from '../network';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
	labelCol: { span: 2 },
	wrapperCol: { span: 22 },
};
const tailLayout = {
	wrapperCol: { offset: 0, span: 24 },
};

interface MatchParams {
	campId: string;
}

const CampUserUpdateScreen = () => {
	let history = useHistory();
	const [form] = Form.useForm();
	let { params } = useRouteMatch<MatchParams>();

	const [campData, setCampData] = useState<any>({});
	const [uploadedImageData, setUploadedImageData] = useState<any>([]);
	const [bucketName, setBucketName] = useState('');
	const [isFacility, setIsFacility] = useState(false);
	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getCamp();
	}, []);

	const getCamp = async () => {
		const response = await getUserCampRequest(params.campId);
		const images: any = [];
		if (response.status) {
			setCampData(response);
			response.imagePaths?.forEach((img: any, index: number) => {
				images.push({
					url: img,
					status: 'done',
					uid: `img_${index}`,
				});
			});
			setImages(images);
			setUploadedImageData(images);
			form.setFieldsValue(response);
			setLoading(false);
		}
	};

	const onUploadChange = ({ uploadedImages, fileList }: any) => {
		setUploadedImageData(fileList);
	};

	const convertBase64ToUrls = async () => {
		const imageArray: any = [];
		const imageUrls: any = [];
		let index = 0;
		for (const image of uploadedImageData) {
			if (image.url.includes('data')) {
				let formattedContent = '';
				let format = '';
				formattedContent = image.url?.split('base64,');
				format = formattedContent[0]?.split(';')[0].replace('data:', '');
				imageArray.push({
					fileName: formattedContent[1],
					type: format,
					bucketName,
					title: `userImage${index++}`,
				});
			} else {
				imageUrls.push(image.url);
			}
		}

		for (const images of imageArray) {
			if (images.fileName) {
				const response = await uploadImageRequest(images);
				if (response.status) {
					imageUrls.push(response.Location);
				}
			}
		}

		return imageUrls;
	};

	const onFinish = async (values: any) => {
		values.imagePaths = await convertBase64ToUrls();
		delete values.buckets;
		const response = await postCampRequest(values);
		if (response.status) {
			history.goBack();
		}
		// api req
	};

	return (
		<Row>
			<Col span={24} style={{ paddingTop: 20 }}>
				<Breadcrumb>
					<Breadcrumb.Item>
						<Link to={'/camp/userCampSites'}>Kullanıcı Kamp Alanları</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>{campData.title}</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title="Alanı Kaydet"
				/>
			</Col>
			<Col span={24} style={{ marginTop: '20px' }}>
				<Loading loading={loading}>
					<Form
						size={'large'}
						{...layout}
						form={form}
						name="control-ref"
						scrollToFirstError={true}
						onFinish={onFinish}
					>
						{!loading && (
							<>
								<Form.Item
									name={'buckets'}
									label={'Bucket Name'}
									rules={[{ required: true }]}
								>
									<Input
										defaultValue={bucketName}
										onChange={(e) => setBucketName(e.target.value)}
									/>
								</Form.Item>
								<Form.Item
									name={'imagePaths'}
									label={'Images'}
									rules={[{ required: true }]}
								>
									<UploadImage
										onChange={onUploadChange}
										imageData={images}
										bucketName={bucketName}
									/>
								</Form.Item>
							</>
						)}
						<Form.Item
							name={'title'}
							label={'Title'}
							rules={[{ required: true }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name={'desc'}
							label={'Description'}
							rules={[{ required: true }]}
						>
							<TextArea rows={4} />
						</Form.Item>
						<Row gutter={8}>
							<Col span={12}>
								<Form.Item
									labelCol={{ span: 4 }}
									name={'city'}
									label={'City'}
									rules={[{ required: true }]}
								>
									<Select virtual={false} showSearch>
										{Cities.map((city, index) => (
											<Option key={index} value={city}>
												{city}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									labelCol={{ span: 3 }}
									name={'county'}
									label={'County'}
									rules={[{ required: true }]}
								>
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={12}>
								<Form.Item
									labelCol={{ span: 4 }}
									name={'region'}
									label={'Region'}
									rules={[{ required: true }]}
								>
									<Select>
										<Option value={1}>Ege</Option>
										<Option value={2}>Marmara</Option>
										<Option value={3}>Karadeniz</Option>
										<Option value={4}>Akdeniz</Option>
										<Option value={5}>İç Anadolu</Option>
										<Option value={6}>Doğu Anadolu</Option>
										<Option value={7}>Güneydoğu Anadolu</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									labelCol={{ span: 3 }}
									name={'category'}
									label={'Category'}
									rules={[{ required: true }]}
								>
									<Select>
										<Option value={'1'}>Ormanlık</Option>
										<Option value={'2'}>Göl</Option>
										<Option value={'3'}>Sahil</Option>
										<Option value={'4'}>Yayla</Option>
										<Option value={'5'}>Dağlık</Option>
										<Option value={'6'}>Doğal</Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Form.Item
							labelCol={{ span: 2 }}
							name={'isFacility'}
							label={'Is Facility'}
							valuePropName="checked"
							rules={[{ required: false }]}
						>
							<Checkbox onChange={(e) => setIsFacility(e.target.checked)} />
						</Form.Item>
						<Col span={12}>
							<Form.Item
								labelCol={{ span: 4 }}
								name={'placeType'}
								label={'Place Type'}
								rules={[{ required: true }]}
							>
								<Select mode="multiple">
									<Option value={'camping'}>Camping</Option>
									<Option value={'facility'}>Facility</Option>
									<Option value={'glamping'}>Glamping</Option>
									<Option value={'bunglow'}>Bunglows</Option>
									<Option value={'caravan'}>Caravans</Option>
									<Option value={'climbing'}>Climbing</Option>
								</Select>
							</Form.Item>
						</Col>
						{isFacility && (
							<Row gutter={8}>
								<Col span={8}>
									<Form.Item
										labelCol={{ span: 12 }}
										name={'phone'}
										label={'Facility Phone'}
										rules={[{ required: true }]}
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										labelCol={{ span: 12 }}
										name={'web'}
										label={'Facility Website'}
										rules={[{ required: true }]}
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										labelCol={{ span: 12 }}
										name={'socialMedia'}
										label={'Facility SocialMedia'}
										rules={[{ required: true }]}
									>
										<Input />
									</Form.Item>
								</Col>
							</Row>
						)}
						<Facilities />
						<Row gutter={8}>
							<Col span={4}>
								<Form.Item
									labelCol={{ span: 12 }}
									name={'lat'}
									label={'Latitude'}
									rules={[{ required: true }]}
								>
									<InputNumber />
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									labelCol={{ span: 7 }}
									name={'long'}
									label={'Longitude'}
									rules={[{ required: true }]}
								>
									<InputNumber />
								</Form.Item>
							</Col>
						</Row>
						<Form.Item {...tailLayout} style={{ textAlign: 'right' }}>
							<Button type="primary" htmlType="submit" style={{ width: '20%' }}>
								Save
							</Button>
						</Form.Item>
					</Form>
				</Loading>
			</Col>
		</Row>
	);
};

export default CampUserUpdateScreen;
