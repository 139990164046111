class QuillEditor {
	private _quillEditor: any;

	initQuillEditor = (instance: any) => {
		this._quillEditor = instance;
	};

	get quillRef() {
		return this._quillEditor.current;
	}
}

export default new QuillEditor();
