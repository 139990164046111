import { Row, Col } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Loading from 'src/components/Loading';
import MarketingUpdateScreen from './Sections/MarketingUpdateScreen';
import MarketingAddScreen from './Sections/MarketingAddScreen';
import MarketingListScreen from './Sections/MarketingListScreen';

const FeedbackScreen = () => {
	let { path } = useRouteMatch();
	const loading = false;

	return (
		<Loading loading={loading}>
			<Row>
				<Col
					span={24}
					style={{
						width: '100%',
						display: 'block',
						textAlign: 'left',
						backgroundColor: 'white',
						padding: '0px 20px',
					}}
				>
					<Switch>
						<Route exact path={path} component={MarketingListScreen} />
						<Route
							exact
							path={`${path}/create`}
							component={MarketingAddScreen}
						/>
						<Route
							exact
							path={`${path}/:marketingId`}
							component={MarketingUpdateScreen}
						/>
					</Switch>
				</Col>
			</Row>
		</Loading>
	);
};

export default FeedbackScreen;
