import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Row, Col, PageHeader, Table, Popconfirm } from 'antd';
import { useRouteMatch, useLocation, Link } from 'react-router-dom';
import { RootState } from 'src/redux/ducks';
import getColumnSearchProps from 'src/components/SearchableColumn';
import { getUserList } from 'src/redux/ducks/user';

const UsersScreen = () => {
	const dispatch = useDispatch();
	let { url } = useRouteMatch();
	let location = useLocation();
	let [currentPage, setCurrentPage] = useState<number>(1);
	const { users, count, loading } = useSelector(
		(state: RootState) => state.user,
	);

	const columns: any = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			...getColumnSearchProps('id'),
			sorter: (a: any, b: any) => {
				return a.id?.localeCompare(b.id);
			},
			render: (_: number, record: any) => {
				return (
					<Link style={{ marginRight: 15 }} to={`${url}/edit/${record.sku}`}>
						{record.id}
					</Link>
				);
			},
		},
		{
			title: 'İsim Soyisim',
			dataIndex: 'fullName',
			key: 'fullName',
			...getColumnSearchProps('fullName'),
		},
		{
			title: 'Kayıt Olma Tarihi',
			dataIndex: 'createdAt',
			render: (_: number, record: any) => {
				return `${moment(record.createdAt).format('DD/MM/YYYY HH: mm')}`;
			},
		},
		{
			title: 'Operation',
			dataIndex: 'Operation',
			editable: false,
			fixed: 'right',
			width: 150,
			render: (_: undefined, record: any) => (
				<Popconfirm title="Sure to delete?" onConfirm={() => {}}>
					<span style={{ color: '#1890ff', cursor: 'pointer' }}>Delete</span>
				</Popconfirm>
			),
		},
	];

	useEffect(() => {
		const getPage = location.search.split('=')[1];
		if (getPage) {
			setCurrentPage(parseInt(getPage));
			getApiRequest(parseInt(getPage));
		} else {
			getApiRequest(currentPage);
		}
	}, []);

	const getApiRequest = (page: number) => {
		dispatch(getUserList(page));
	};

	const onPaginationChange = (page: any, pageSize: any) => {
		getApiRequest(page);
		window.history.replaceState(null, '', `/users?page=${page}`);
		setCurrentPage(page);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};

	const onTableChange = (pagination: any, filters: any, sorter: any) => {
		// getApiRequest(pagination.current);
		// window.history.replaceState(null, '', `/pim?page=${pagination.current}`);
		// setCurrentPage(pagination.current);
		// if (filters.sku) {
		// 	setFilter(filters.sku[0]);
		// } else if (filters.variantGroupId) {
		// 	setFilter(filters.variantGroupId[0]);
		// } else {
		// 	setFilter('');
		// }
	};

	return (
		<Row>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title={`${count} Kullanıcı`}
				/>
			</Col>

			<Col span={24} style={{ marginTop: '15px' }}>
				<Table
					dataSource={users}
					// bordered
					columns={columns}
					loading={loading}
					onChange={onTableChange}
					pagination={{
						current: currentPage,
						showSizeChanger: false,
						pageSize: 20,
						total: count,
						onChange: onPaginationChange,
					}}
				/>
			</Col>
		</Row>
	);
};

export default UsersScreen;
