import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Row, Col, PageHeader, Table, Popconfirm } from 'antd';
import { useRouteMatch, useLocation, Link } from 'react-router-dom';
import { RootState } from 'src/redux/ducks';
import getColumnSearchProps from 'src/components/SearchableColumn';
import { getBusinessUserList } from 'src/redux/ducks/user';
import { deleteBusinessUserRequest } from '../network';

const BusinessUsersListScreen = () => {
	const dispatch = useDispatch();
	let { url } = useRouteMatch();
	let location = useLocation();
	let [currentPage, setCurrentPage] = useState<number>(1);
	const { businessUsers, businessCount, businessLoading } = useSelector(
		(state: RootState) => state.user,
	);

	const columns: any = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			...getColumnSearchProps('id'),
			sorter: (a: any, b: any) => {
				return a.id?.localeCompare(b.id);
			},
			render: (_: number, record: any) => {
				return (
					<Link style={{ marginRight: 15 }} to={`${url}/update/${record.id}`}>
						{record.id}
					</Link>
				);
			},
		},
		{
			title: 'Thumbnail',
			dataIndex: 'profilePicture',
			render: (_: number, record: any) => {
				if (record.profilePicture) {
					return (
						<img
							src={record.profilePicture}
							alt="thumbnail"
							width={75}
							height={75}
						/>
					);
				}
				return (
					<div
						style={{
							backgroundColor: 'rgba(100,100,100, 0.3)',
							width: 75,
							height: 75,
						}}
					/>
				);
			},
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			...getColumnSearchProps('email'),
		},
		{
			title: 'İsim Soyisim',
			dataIndex: 'fullName',
			key: 'fullName',
			...getColumnSearchProps('fullName'),
		},
		{
			title: 'Sosyal Medya Adresi',
			dataIndex: 'profileSocialMedia',
			key: 'profileSocialMedia',
			render: (_: number, record: any) => {
				return (
					<a href={record.profileSocialMedia} target="_blank" rel="noreferrer">
						{record.profileSocialMedia}
					</a>
				);
			},
		},
		{
			title: 'Kayıt Olma Tarihi',
			dataIndex: 'createdAt',
			render: (_: number, record: any) => {
				return `${moment(record.createdAt).format('DD/MM/YYYY')}`;
			},
		},
		{
			title: 'Yetkinlik',
			dataIndex: 'role',
		},
		{
			title: 'Durum',
			dataIndex: 'active',
			render: (_: number, record: any) => {
				return record.active ? 'Aktif' : 'Pasif';
			},
		},
		{
			title: 'Operation',
			dataIndex: 'Operation',
			editable: false,
			fixed: 'right',
			width: 150,
			render: (_: undefined, record: any) => {
				return (
					<>
						{!record.active && (
							<Popconfirm
								title="Aktif etmek istediğine emin misin?"
								onConfirm={() => {}}
							>
								<span
									style={{
										color: '#1890ff',
										cursor: 'pointer',
										marginRight: 15,
									}}
								>
									Aktif Et
								</span>
							</Popconfirm>
						)}
						{record.role !== 'ADMIN' && (
							<Popconfirm
								title="Silmek istediğine emin misin?"
								onConfirm={() => deleteBusinessUser(record.id)}
							>
								<span style={{ color: 'red', cursor: 'pointer' }}>Sil</span>
							</Popconfirm>
						)}
					</>
				);
			},
		},
	];

	useEffect(() => {
		const getPage = location.search.split('=')[1];
		if (getPage) {
			setCurrentPage(parseInt(getPage));
			getApiRequest(parseInt(getPage));
		} else {
			getApiRequest(currentPage);
		}
	}, []);

	const getApiRequest = (page: number) => {
		dispatch(getBusinessUserList(page));
	};

	const onPaginationChange = (page: any, pageSize: any) => {
		getApiRequest(page);
		window.history.replaceState(null, '', `/users?page=${page}`);
		setCurrentPage(page);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};

	const onTableChange = (pagination: any, filters: any, sorter: any) => {
		// getApiRequest(pagination.current);
		// window.history.replaceState(null, '', `/pim?page=${pagination.current}`);
		// setCurrentPage(pagination.current);
		// if (filters.sku) {
		// 	setFilter(filters.sku[0]);
		// } else if (filters.variantGroupId) {
		// 	setFilter(filters.variantGroupId[0]);
		// } else {
		// 	setFilter('');
		// }
	};

	const deleteBusinessUser = async (id: string) => {
		const response = await deleteBusinessUserRequest(id);
		if (response.status) {
			dispatch(getBusinessUserList(currentPage));
		} else {
		}
	};

	return (
		<Row>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title={`${businessCount} Kullanıcı`}
				/>
			</Col>

			<Col span={24} style={{ marginTop: '15px' }}>
				<Table
					dataSource={businessUsers}
					bordered
					columns={columns}
					loading={businessLoading}
					onChange={onTableChange}
					pagination={{
						current: currentPage,
						showSizeChanger: false,
						pageSize: 20,
						total: businessCount,
						onChange: onPaginationChange,
					}}
				/>
			</Col>
		</Row>
	);
};

export default BusinessUsersListScreen;
