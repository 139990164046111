import { request } from 'src/utils/request';

const BUSINESS_REGISTER = 'businessRegister';

export async function adminLogin(data: any) {
	try {
		const result: any = await request(BUSINESS_REGISTER, 'POST', data);
		if (result.status) {
			return { status: true, ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}
