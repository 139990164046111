import { Row, Col } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Loading from 'src/components/Loading';

import './index.scss';
import EventCreateScreen from './Sections/EventCreate.screen';
import EventUpdateScreen from './Sections/EventUpdate.screen';
import EventListScreen from './Sections/EventList.screen';

const EventScreen = () => {
	let { path } = useRouteMatch();
	const loading = false;

	return (
		<Loading loading={loading}>
			<Row>
				<Col
					span={24}
					style={{
						width: '100%',
						display: 'block',
						textAlign: 'left',
						backgroundColor: 'white',
						padding: '0px 20px',
					}}
				>
					<Switch>
						<Route exact path={path} component={EventListScreen} />
						<Route
							exact
							path={`${path}/create`}
							component={EventCreateScreen}
						/>
						<Route
							exact
							path={`${path}/update/:eventId`}
							component={EventUpdateScreen}
						/>
					</Switch>
				</Col>
			</Row>
		</Loading>
	);
};

export default EventScreen;
