import axios from 'axios';
import helpers from 'src/utils/helpers';
//
const API_URL = 'https://kampi.kampp.in/v1';
// const DEV_API_URL = 'http://207.154.196.158:5454/v1';
// const API_URL = 'http://localhost:5454/v1';

axios.interceptors.response.use(
	(response) => {
		return response.data;
	},
	(error) => {
		if (error.response.status === 401) {
			helpers.removeAuthToken();
		}
		return Promise.reject(error);
	},
);

export const request = (url, method, data, isSearch = false) => {
	const language = helpers.getAppLanguage();
	let formattedApiUrl = '';

	if (url.includes('&')) {
		formattedApiUrl = `${API_URL}/${url}&lang=${language}`;
	} else {
		formattedApiUrl = `${API_URL}/${url}?lang=${language}`;
	}

	const apiUrl = isSearch ? `${API_URL}/${url}` : formattedApiUrl;

	return axios(apiUrl, {
		method,
		// credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/json',
			Authorization: helpers.getAuthToken(),
		},
		data,
	});
};

export const logout = () => {
	//
};
