import React, { useState, useEffect } from 'react';
import {
	Form,
	Input,
	Button,
	Col,
	Row,
	Breadcrumb,
	PageHeader,
	Select,
	Checkbox,
} from 'antd';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import UploadImage from 'src/components/UploadImage';
import Loading from 'src/components/Loading';
import { getBusinessUserRequest, updateBusinessUserRequest } from '../network';

const { Option } = Select;

const layout = {
	labelCol: { span: 2 },
	wrapperCol: { span: 22 },
};
const tailLayout = {
	wrapperCol: { offset: 0, span: 24 },
};

interface MatchParams {
	userId: string;
}

const BusinessUsersUpdateScreen = () => {
	let history = useHistory();
	const [form] = Form.useForm();
	let { params } = useRouteMatch<MatchParams>();

	const [userData, setUserData] = useState<any>({});
	const [uploadedImageData, setUploadedImageData] = useState<any>([]);
	const [thumbnail, setThumbnail] = useState('');
	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getBusinessUser();
	}, []);

	const getBusinessUser = async () => {
		const response = await getBusinessUserRequest(params.userId);
		const images: any = [];
		if (response.status) {
			setUserData(response);
			if (response.profilePicture) {
				images.push({
					url: response.profilePicture,
					status: 'done',
					uid: `img_${0}`,
				});
				setImages(images);
				setUploadedImageData(images);
			}
			form.setFieldsValue(response);
			setLoading(false);
		}
	};

	const onUploadChange = ({ uploadedImages, fileList }: any) => {
		setUploadedImageData(fileList);
	};
	const onThumbnailChange = (url: string) => {
		setThumbnail(url);
	};

	const onFinish = async (values: any) => {
		values.profilePicture = uploadedImageData?.[0]?.url;
		const params = { ...userData, ...values };
		delete params.imagePaths;
		delete params.status;

		const response = await updateBusinessUserRequest(params);
		if (response.status) {
			history.goBack();
		}
	};

	return (
		<Row>
			<Col span={24} style={{ paddingTop: 20 }}>
				<Breadcrumb>
					<Breadcrumb.Item>
						<Link to={'/business-users'}>Kurumsal Kullanıcılar</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>{userData.fullName}</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title="Kullanıcıyı Güncelle"
				/>
			</Col>
			<Col span={24} style={{ marginTop: '20px' }}>
				<Loading loading={false}>
					<Form
						size={'large'}
						{...layout}
						form={form}
						name="control-ref"
						scrollToFirstError={true}
						onFinish={onFinish}
					>
						{!loading && (
							<>
								<Form.Item
									name={'imagePaths'}
									label={'Fotoğraf'}
									rules={[{ required: true }]}
								>
									<UploadImage
										onChange={onUploadChange}
										imageData={images}
										bucketName={'ProfilePictures'}
										onThumbnailChange={onThumbnailChange}
									/>
								</Form.Item>
							</>
						)}
						<Form.Item
							name={'fullName'}
							label={'İsim Soyisim'}
							rules={[{ required: true }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name={'email'}
							label={'E-Posta'}
							rules={[{ required: true }]}
						>
							<Input disabled />
						</Form.Item>
						<Form.Item
							name={'role'}
							label={'Yetkinlik'}
							rules={[{ required: true }]}
						>
							<Select virtual={false} disabled>
								<Option value={'ADMIN'}>Admin</Option>
								<Option value={'PARTNER'}>Partner</Option>
							</Select>
						</Form.Item>
						<Form.Item
							name={'profileSocialMedia'}
							label={'Sosyal Medya'}
							rules={[{ required: false }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							labelCol={{ span: 2 }}
							name={'active'}
							label={'Onay Durumu'}
							valuePropName="checked"
							rules={[{ required: false }]}
						>
							<Checkbox />
						</Form.Item>
						<Form.Item {...tailLayout} style={{ textAlign: 'right' }}>
							<Button type="primary" htmlType="submit" style={{ width: '20%' }}>
								Güncelle
							</Button>
						</Form.Item>
					</Form>
				</Loading>
			</Col>
		</Row>
	);
};

export default BusinessUsersUpdateScreen;
