import { request } from 'src/utils/request';

export async function getCommentListRequest(page: number) {
	const GET_COMMENTS = `comment?page=${page}&pageSize=20`;
	try {
		const result: any = await request(GET_COMMENTS, 'GET');
		if (result.status) {
			return { status: true, ...result.data };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function approveCommentRequest(commentId: number) {
	const APPROVE_COMMENT = `approveComment/${commentId}`;
	try {
		const result: any = await request(APPROVE_COMMENT, 'PUT');
		if (result.status) {
			return { status: true, ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function getCommentRequest(commentId: string) {
	const GET_COMMENT = `commentOne/${commentId}`;
	try {
		const result: any = await request(GET_COMMENT, 'GET');
		if (result.status) {
			return { status: true, ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function deleteCommentRequest(commentId: number) {
	const DELETE_COMMENT = `comment/${commentId}`;
	try {
		const result: any = await request(DELETE_COMMENT, 'DELETE');
		if (result.status) {
			return { status: true, ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}
