import React from 'react';
import { Button, Col, Divider, Layout, Row, Form, Input, Modal } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from 'src/redux/ducks/app';
import { adminLogin } from './network';

const { Content } = Layout;

const Register = () => {
	let history = useHistory();
	const dispatch = useDispatch();

	const onFinish = async (values: any) => {
		const { fullName, email, password } = values;
		const postData = { fullName, email, password };
		const response: any = await adminLogin(postData);
		dispatch(setLoading(false));

		if (response.status) {
			Modal.success({
				title: 'Başarılı',
				content: response.responseMessage,
				onOk() {
					history.push('/login');
				},
			});
		} else {
			Modal.error({
				title: 'Hata',
				content: response.message,
			});
		}
	};

	return (
		<Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
			<Col
				xs={{ span: 18 }}
				lg={{ span: 12 }}
				xl={{ span: 10 }}
				md={{ span: 12 }}
				xxl={{ span: 6 }}
			>
				<Content style={{ overflow: 'initial' }}>
					<div
						className={'site-layout-background'}
						style={{
							padding: '48px',
							textAlign: 'center',
							backgroundColor: 'white',
							borderRadius: 10,
						}}
					>
						<img
							src={'https://kampp.in/logo.png'}
							onClick={() => history.push('/login')}
							style={{
								height: '80px',
								objectFit: 'contain',
								cursor: 'pointer',
							}}
							alt={'Kampp Logo'}
						/>
						<Divider />
						<Form
							name="normal_login"
							className="login-form"
							onFinish={onFinish}
						>
							<Form.Item
								name="fullName"
								rules={[
									{
										required: true,
										message: 'Lütfen kullanıcı adınızı eksiksiz doldurun.',
									},
								]}
							>
								<Input
									prefix={<UserOutlined className="site-form-item-icon" />}
									placeholder="İsim Soyisim"
									size="large"
								/>
							</Form.Item>
							<Form.Item
								name="email"
								rules={[
									{
										required: true,
										message: 'Lütfen Email adresini eksiksiz doldurun.',
									},
									{
										type: 'email',
										message: 'Lütfen geçerli bir email adresi ekleyin.',
									},
								]}
							>
								<Input
									prefix={<UserOutlined className="site-form-item-icon" />}
									placeholder="Email"
									autoComplete="new-password"
									size="large"
								/>
							</Form.Item>
							<Form.Item
								name="password"
								rules={[{ required: true, message: 'Lütfen şifrenizi girin' }]}
								hasFeedback
							>
								<Input.Password
									autoComplete="new-password"
									prefix={<LockOutlined className="site-form-item-icon" />}
									placeholder="Şifre"
									size="large"
								/>
							</Form.Item>
							<Form.Item
								name="confirm"
								dependencies={['password']}
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Lütfen şifrenizi kontrol edin',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue('password') === value) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error('Şifreler birbiri ile uymuyor.'),
											);
										},
									}),
								]}
							>
								<Input.Password
									prefix={<LockOutlined className="site-form-item-icon" />}
									placeholder="Şifre Tekrar"
									size="large"
								/>
							</Form.Item>

							<Form.Item>
								<Button
									type="primary"
									htmlType="submit"
									block
									size="large"
									className="login-form-button"
								>
									Hesabımı Oluştur
								</Button>
							</Form.Item>
						</Form>
						<Divider />
						Kampp Yazılım ©{new Date().getFullYear()} |{' '}
						<a href={'https://www.kampp.in'} target={'_blank'} rel="noreferrer">
							www.kampp.in
						</a>
						<br />
					</div>
				</Content>
			</Col>
		</Row>
	);
};

export default Register;
