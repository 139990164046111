import typeToReducer, { setTypeDelimiter } from 'type-to-reducer';
import {
	createAction,
	createAsyncAction,
} from 'redux-promise-middleware-actions';
import { getFeedbacksRequest } from 'src/containers/Feedback/network';

setTypeDelimiter('@');

// Actions
const SET_PAGE = 'feedback/SET_PAGE';
const GET_FEEDBACK_LIST = 'feedback/GET_FEEDBACK_LIST';

// Action Creators
export const setPage = createAction(SET_PAGE, (loading) => loading);
export const getFeedbackList = createAsyncAction(
	GET_FEEDBACK_LIST,
	async (page) => {
		try {
			const data = await getFeedbacksRequest(page);
			return data;
		} catch (error) {
			console.warn('API :: ' + GET_FEEDBACK_LIST, error);
			throw error;
		}
	},
);

// Reducer
const initialState = {
	page: 1,
	loading: false,
	feedbacks: [],
	count: 0,
};

export default typeToReducer(
	{
		[SET_PAGE]: (state, action) => ({
			...state,
			page: action.payload,
		}),
		[GET_FEEDBACK_LIST]: {
			PENDING: (state, action) => {
				return {
					...state,
					loading: true,
				};
			},
			REJECTED: (state, action) => {
				return {
					...state,
					loading: false,
				};
			},
			FULFILLED: (state, action) => {
				return {
					...state,
					loading: false,
					feedbacks: action.payload.feedbackData,
					count: action.payload.count,
				};
			},
		},
	},
	initialState,
);
