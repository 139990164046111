export const Cities = [
"Adana",
"Adıyaman",
"Afyonkarahisar",
"Ağrı",
"Amasya",
"Ankara",
"Antalya",
"Artvin",
"Aydin",
"Balıkesir",
"Bilecik",
"Bingöl",
"Bitlis",
"Bolu",
"Burdur",
"Bursa",
"Çanakkale",
"Çankırı",
"Çorum",
"Denizli",
"Diyarbakir",
"Edirne",
"Elazığ",
"Erzincan",
"Erzurum",
"Eskişehir",
"Gaziantep",
"Giresun",
"Gümüşhane",
"Hakkari",
"Hatay",
"Isparta",
"Mersin",
"İstanbul",
"İzmir",
"Kars",
"Kastamonu",
"Kayseri",
"Kırklareli",
"Kırşehir",
"Kocaeli",
"Konya",
"Kütahya",
"Malatya",
"Manisa",
"Kahramanmaraş",
"Mardin",
"Muğla",
"Muş",
"Nevşehir",
"Niğde",
"Ordu",
"Rize",
"Sakarya",
"Samsun",
"Sirt",
"Sinop",
"Sivas",
"Tekirdağ",
"Tokat",
"Trabzon",
"Tunceli",
"Şanlıurfa",
"Uşak",
"Van",
"Yozgat",
"Zonguldak",
"Aksaray",
"Bayburt",
"Karaman",
"Kırıkkale",
"Batman",
"Şırnak",
"Bartın",
"Ardahan",
"Iğdır",
"Yalova",
"Karabük",
"Kilis",
"Osmaniye",
"Düzce"
];

export const layout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 20 },
};
export const tailLayout = {
	labelCol: { span: 8 },
	wrapperCol: { offset: 0, span: 24 },
};

export const rowLayout = {
	labelCol: { span: 12 },
};

export const config = {
	rules: [
		{ type: 'object' as const, required: true, message: 'Please select time!' },
	],
};

export const rangeConfig = {
	rules: [
		{ type: 'array' as const, required: true, message: 'Please select time!' },
	],
};
