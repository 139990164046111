import { useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Col, PageHeader, Table, Popconfirm, Button } from 'antd';
import { useRouteMatch, Link } from 'react-router-dom';

import getColumnSearchProps from 'src/components/SearchableColumn';
import { getEventListRequest, deleteEventRequest } from '../network';

const EventListScreen = () => {
	let { url } = useRouteMatch();
	let [eventData, setEventData] = useState([]);
	let [count, setCount] = useState(0);
	let [loading, setLoading] = useState(false);
	let [currentPage, setCurrentPage] = useState<number>(1);

	moment.locale('tr_TR');

	const columns: any = [
		{
			title: 'Kapak Fotoğrafı',
			dataIndex: 'image',
			render: (_: number, record: any) => {
				return (
					<img
						style={{ objectFit: 'cover' }}
						src={record.image}
						alt="coverImage"
						width={75}
						height={75}
					/>
				);
			},
		},
		{
			title: 'Başlık',
			dataIndex: 'title',
			key: 'title',
			...getColumnSearchProps('title'),
			sorter: {
				compare: (a: any, b: any) => a.id - b.id,
			},
			render: (_: number, record: any) => {
				return (
					<Link style={{ marginRight: 15 }} to={`event/update/${record.id}`}>
						{record.title}
					</Link>
				);
			},
		},
		{
			title: 'Başlangıç Tarihi',
			dataIndex: 'startDate',
			render: (_: number, record: any) => {
				return `${moment(record.startDate).format('DD MMMM YYYY')}`;
			},
		},
		{
			title: 'Bitiş Tarihi',
			dataIndex: 'endDate',
			render: (_: number, record: any) => {
				return `${moment(record.endDate).format('DD MMMM YYYY')}`;
			},
		},
		{
			title: 'İşlemler',
			dataIndex: 'Operation',
			editable: false,
			fixed: 'right',
			width: 150,
			render: (_: undefined, record: any) => (
				<Popconfirm
					title="Sure to delete?"
					onConfirm={() => deleteEvent(record.id)}
				>
					<span style={{ color: '#1890ff', cursor: 'pointer' }}>Delete</span>
				</Popconfirm>
			),
		},
	];

	const getEvents = async (page: number, pageSize: number) => {
		setLoading(true);
		const response = await getEventListRequest(page, pageSize);
		setLoading(false);
		if (response.status) {
			setEventData(response.data.eventData);
			setCount(response.data.count);
		}
	};

	const deleteEvent = async (eventId: string) => {
		const resp = await deleteEventRequest(eventId);
		if (resp.status) {
			getEvents(1, 20);
		}
	};

	useEffect(() => {
		getEvents(1, 20);
	}, []);

	const onTableChange = (pagination: any, filters: any, sorter: any) => {
		let _page = pagination.current;
		if (currentPage !== pagination.current) {
			window.history.replaceState(
				null,
				'',
				`/event/?page=${pagination.current}`,
			);
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		}

		setCurrentPage(pagination.current);
		getEvents(_page, 20);
	};

	return (
		<Row>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title={`Etkinlikler`}
					extra={[
						<Button key={1} type={'primary'}>
							<Link to={`${url}/create`}>Yeni Etkinlik Ekle</Link>
						</Button>,
					]}
				/>
			</Col>

			<Col span={24} style={{ marginTop: '15px' }}>
				<Table
					dataSource={eventData}
					// bordered
					rowKey={(obj: any) => obj.id}
					columns={columns}
					loading={loading}
					onChange={onTableChange}
					pagination={{
						current: currentPage,
						pageSize: 20,
						total: count,
					}}
				/>
			</Col>
		</Row>
	);
};

export default EventListScreen;
