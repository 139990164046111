import { Row, Col } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Loading from 'src/components/Loading';
import FeedbackUpdateScreen from './Sections/FeedbackUpdate.screen';
import FeedbackListScreen from './Sections/FeedbackList.screen';

const FeedbackScreen = () => {
	let { path } = useRouteMatch();
	const loading = false;

	return (
		<Loading loading={loading}>
			<Row>
				<Col
					span={24}
					style={{
						width: '100%',
						display: 'block',
						textAlign: 'left',
						backgroundColor: 'white',
						padding: '0px 20px',
					}}
				>
					<Switch>
						<Route exact path={path} component={FeedbackListScreen} />
						<Route
							exact
							path={`${path}/:feedbackId`}
							component={FeedbackUpdateScreen}
						/>
					</Switch>
				</Col>
			</Row>
		</Loading>
	);
};

export default FeedbackScreen;
