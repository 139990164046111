import { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';

import QuillEditor from 'src/services/QuillEditor';
import EditorToolbar, { modules, formats } from 'src/components/EditorToolbar';

import 'react-quill/dist/quill.snow.css';

interface Props {
	value: any;
	onChange: any;
}

const HtmlEditor = ({ value, onChange }: Props) => {
	const quillRef = useRef<any>(null);

	useEffect(() => {
		if (quillRef && quillRef.current) {
			QuillEditor.initQuillEditor(quillRef);
		}
	}, [quillRef]);

	return (
		<>
			<EditorToolbar />
			<ReactQuill
				ref={quillRef}
				id="quill"
				theme="snow"
				value={value || ''}
				onChange={onChange}
				modules={modules}
				formats={formats}
				style={{ height: 400, marginBottom: 40 }}
			/>
		</>
	);
};

export default HtmlEditor;
