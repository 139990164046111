import { message } from 'antd';
import Resizer from 'react-image-file-resizer';

export const getBase64 = (img: any, callback: any) => {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
};

export const imageSizeControl = (file: any) => {
	const isJpgOrPng =
		file.type === 'image/jpeg' ||
		file.type === 'image/png' ||
		file.type === 'image/webp';
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 15;
	if (!isLt2M) {
		message.error('Image must smaller than 15MB!');
	}
	return isJpgOrPng && isLt2M;
};

export const fileDownloader = (url: string) => {
	if (url && document) {
		const link = document.createElement('a');
		link.href = url;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
};

export const imageFileSelector = async (
	file: any,
	resizeConfigData: any,
	noBase64 = false,
	namePrefix = '',
) => {
	let base64Content: any = '';
	let formattedContent = '';
	let format = '';

	if (!noBase64) {
		base64Content = await resizeFile(file);
		formattedContent = base64Content?.split('base64,');
		format = formattedContent[0].split(';')[0].replace('data:', '');
	} else {
		formattedContent = file.thumbUrl?.split('base64,');
		format = formattedContent[0]?.split(';')[0].replace('data:', '');
	}

	const fileName =
		file.name || file.url.substring(file.url.lastIndexOf('/') + 1);

	const params: any = {
		data: formattedContent[1],
		contentType: format,
		contentEncoding: 'base64',
		alias: `${namePrefix}${fileName.replace(/\s/g, '')}`,
	};

	noBase64 && delete params.data;

	return params;
};

export const _getBase64 = (file: any) => {
	return new Promise((resolve, reject): any => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
};

const resizeFile = (file: any) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			1280,
			1280,
			'JPEG',
			80,
			0,
			(uri) => {
				resolve(uri);
			},
			'base64',
		);
	});
