import React from 'react';
import { Spin } from 'antd';

type Props = {
	loading: boolean;
	fullscreen?: boolean;
	children: any;
};

const Loading: React.FC<Props> = ({
	loading,
	children,
	fullscreen = false,
}) => {
	if (fullscreen) {
		return (
			<Spin spinning={loading} delay={200}>
				{children}
			</Spin>
		);
	}
	return (
		<div>
			{children}
			{/* {loading && (
				<div
					className="loading-container"
					style={{
						backgroundColor: loading ? 'rgba(255,255,255,0.5)' : 'transparent',
					}}>
					<Spin spinning={loading} delay={200} />
				</div>
			)} */}
		</div>
	);
};

export default Loading;
