import typeToReducer, { setTypeDelimiter } from 'type-to-reducer';
import {
	createAction,
	createAsyncAction,
} from 'redux-promise-middleware-actions';
import { getUserListRequest } from 'src/containers/Users/network';
import { getBusinessUserListRequest } from 'src/containers/BusinessUser/network';

setTypeDelimiter('@');

// Actions
const SET_USER = 'aposto/app/SET_USER';
const LOGOUT = 'aposto/app/LOGOUT';
const GET_USERS = 'user/GET_USERS';
const GET_BUSINESS_USERS = 'user/GET_BUSINESS_USERS';
// const TEST_ASYNC_FUNC = 'aposto/app/TEST_ASYNC_FUNC';

// Action Creators
export const setUser = createAction(SET_USER, (userData) => userData);
export const logout = createAction(LOGOUT, () => {});

export const getUserList = createAsyncAction(GET_USERS, async (page) => {
	try {
		const data = await getUserListRequest(page);
		return data;
	} catch (error) {
		console.warn('API :: ' + GET_USERS, error);
		throw error;
	}
});

export const getBusinessUserList = createAsyncAction(
	GET_BUSINESS_USERS,
	async (page) => {
		try {
			const data = await getBusinessUserListRequest(page);
			return data;
		} catch (error) {
			console.warn('API :: ' + GET_BUSINESS_USERS, error);
			throw error;
		}
	},
);

// Reducer
const initialState = {
	userData: null,
	users: [],
	count: 0,
	loading: true,
	businessUsers: [],
	businessCount: 0,
	businessLoading: true,
};

export default typeToReducer(
	{
		[SET_USER]: (state, action) => ({
			...state,
			userData: action.payload,
		}),
		[LOGOUT]: (state, action) => ({
			...state,
			userData: null,
		}),
		[GET_USERS]: {
			PENDING: (state, action) => ({
				...state,
				loading: true,
			}),
			REJECTED: (state, action) => ({
				...state,
				loading: false,
			}),
			FULFILLED: (state, action) => ({
				...state,
				loading: false,
				users: action.payload.users,
				count: action.payload.count,
			}),
		},
		[GET_BUSINESS_USERS]: {
			PENDING: (state, action) => ({
				...state,
				businessLoading: true,
			}),
			REJECTED: (state, action) => ({
				...state,
				businessLoading: false,
			}),
			FULFILLED: (state, action) => ({
				...state,
				businessLoading: false,
				businessUsers: action.payload.users,
				businessCount: action.payload.count,
			}),
		},
	},
	initialState,
);
