import typeToReducer, { setTypeDelimiter } from 'type-to-reducer';
import { createAction } from 'redux-promise-middleware-actions';
import helpers from 'src/utils/helpers';

setTypeDelimiter('@');

// Actions
const SET_LOADING = 'app/SET_LOADING';
const SET_LANGUAGE = 'app/SET_LANGUAGE';

// Action Creators
export const setLoading = createAction(SET_LOADING, (loading) => loading);
export const setLanguage = createAction(SET_LANGUAGE, (language) => language);

// Reducer
const initialState = {
	loading: false,
	language: 'tr',
};

export default typeToReducer(
	{
		[SET_LOADING]: (state, action) => ({
			...state,
			loading: action.payload,
		}),
		[SET_LANGUAGE]: (state, action) => {
			helpers.setAppLanguage(action.payload);
			return {
				...state,
				language: action.payload,
			};
		},
	},
	initialState,
);
