import { request } from 'src/utils/request';

export async function getCampListRequest(page: string) {
	const GET_CAMP_SITES = `camp/partnerCampRecordsPagination?page=${page}&pageSize=20`;
	try {
		const result: any = await request(GET_CAMP_SITES, 'GET');
		if (result.status) {
			return { status: true, ...result.data };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function getUserCampListRequest(page: string) {
	const GET_USER_CAMP_SITES = `camp/getAllUserCampRecords?page=${page}&pageSize=5`;
	try {
		const result: any = await request(GET_USER_CAMP_SITES, 'GET');
		if (result.status) {
			return { status: true, ...result.data };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function getUserCampRequest(campId: string) {
	const GET_CAMP = `camp/getUserCampRecords/${campId}`;
	try {
		const result: any = await request(GET_CAMP, 'GET');
		if (result.id) {
			return { status: true, ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function deleteUserCampPlaceRequest(id: string) {
	const DELETE_USER_CAMP_SITES = `camp/deleteUserCampRecord/${id}`;
	try {
		const result: any = await request(DELETE_USER_CAMP_SITES, 'DELETE');
		if (result.status) {
			return { status: true, ...result.data };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function getCampRequest(campId: string) {
	const GET_CAMP = `camp/partnerCampRecord/${campId}`;
	try {
		const result: any = await request(GET_CAMP, 'GET');
		if (result.id) {
			return { status: true, ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function postCampRequest(data: any) {
	const POST_CAMP = `camp/postCampRecord`;
	try {
		const result: any = await request(POST_CAMP, 'POST', data);
		if (result) {
			return { status: true, ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function updateCampRecord(data: any) {
	const UPDATE_CAMP = `camp/updateCampRecord/${data.id}`;
	try {
		const result: any = await request(UPDATE_CAMP, 'PUT', data);
		if (result) {
			return { status: true, ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function searchCampRequest(searchTerm: string) {
	const POST_CAMP = `search?query=${searchTerm}`;
	try {
		const result: any = await request(POST_CAMP, 'GET', {}, true);
		if (result) {
			return { status: true, ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function uploadImageRequest(params: any) {
	const UPLOAD_IMAGE_TO_S3 = `camp/uploadS3`;
	try {
		const result: any = await request(UPLOAD_IMAGE_TO_S3, 'POST', params);
		if (result.Location) {
			return { status: true, ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function uploadThumbnailRequest(thumbnail: string) {
	const UPLOAD_THUMBNAIL_TO_S3 = `camp/uploadThumbnail`;
	try {
		const result: any = await request(UPLOAD_THUMBNAIL_TO_S3, 'POST', {
			thumbnail,
		});
		if (result.Location) {
			return { status: true, ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}
