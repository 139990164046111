import { useState } from 'react';
import {
	Form,
	Input,
	Col,
	Row,
	Breadcrumb,
	PageHeader,
	Button,
	Select,
	message,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';

import UploadImage from 'src/components/UploadImage';
import Loading from 'src/components/Loading';
import { addMarketingMaterialRequest } from '../network';

const { Option } = Select;

const tailLayout = {
	wrapperCol: { offset: 0, span: 24 },
};

const layout = {
	labelCol: { span: 3 },
	wrapperCol: { span: 22 },
};

const FeedbackUpdateScreen = () => {
	let history = useHistory();
	const [form] = Form.useForm();

	const [uploadedImageData, setUploadedImageData] = useState<any>([]);

	const onUploadChange = ({ uploadedImages, fileList }: any) => {
		setUploadedImageData(fileList);
	};

	const onThumbnailChange = (url: string) => {
		console.log('thumbnail', url);
	};

	const onFinish = async (values: any) => {
		values.image = uploadedImageData?.[0]?.url || values.imageUrl;
		delete values.imagePaths;
		if (values.image) {
			const response = await addMarketingMaterialRequest(values);
			if (response.status) {
				history.goBack();
			}
		} else {
			message.error('Image Url cant be null');
		}
	};

	return (
		<Row>
			<Col span={24} style={{ paddingTop: 20 }}>
				<Breadcrumb>
					<Breadcrumb.Item>
						<Link to={'/marketing'}>Marketing</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>New Marketing Material</Breadcrumb.Item>
				</Breadcrumb>
			</Col>
			<Col span={24}>
				<PageHeader
					className="site-page-header no-left-padding"
					title="Marketing Info"
				/>
			</Col>

			<Col span={24} style={{ marginTop: '20px' }}>
				<Loading loading={false}>
					<Form
						size={'large'}
						{...layout}
						form={form}
						name="control-ref"
						scrollToFirstError={true}
						onFinish={onFinish}
					>
						<Form.Item name={'imagePaths'} label={'Images'}>
							<UploadImage
								onChange={onUploadChange}
								imageData={[]}
								bucketName={'.MarketingMaterial'}
								onThumbnailChange={onThumbnailChange}
							/>
						</Form.Item>
						<Form.Item name={'imageUrl'} label={'Image Url'}>
							<Input />
						</Form.Item>
						<Form.Item
							name={'name'}
							label={'Title'}
							rules={[{ required: true }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name={'url'}
							label={'Link Url'}
							rules={[{ required: true }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							labelCol={{ span: 3 }}
							name={'category'}
							label={'Kategori'}
							rules={[{ required: true }]}
						>
							<Select>
								<Option value={'tent'}>Çadır</Option>
								<Option value={'bag-mat'}>{`Tulum & Mat`}</Option>
								<Option value={'bag'}>Çanta</Option>
								<Option value={'kitchen'}>Kamp Mutfağı</Option>
								<Option value={'chair'}>Sandalye</Option>
								<Option value={'table'}>Masa</Option>
								<Option value={'others'}>İlgi Çekici Diğer Ürünler</Option>
							</Select>
						</Form.Item>
						<Form.Item {...tailLayout} style={{ textAlign: 'right' }}>
							<Button type="primary" htmlType="submit" style={{ width: '20%' }}>
								Ekle
							</Button>
						</Form.Item>
					</Form>
				</Loading>
			</Col>
		</Row>
	);
};

export default FeedbackUpdateScreen;
