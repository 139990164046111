import { request } from 'src/utils/request';

export async function getBusinessUserListRequest(page: string) {
	const GET_USERS = `businessUsers?page=${page}&pageSize=20`;
	try {
		const result: any = await request(GET_USERS, 'GET');
		if (result.status) {
			return { status: true, ...result.data };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function getBusinessUserRequest(id: string) {
	const GET_BUSINESS_USER = `businessUser/${id}`;
	try {
		const result: any = await request(GET_BUSINESS_USER, 'GET');
		if (result.status) {
			return { status: true, ...result.data };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function updateBusinessUserRequest(data: any) {
	const UPDATE_BUSINESS_USER = `updateBusinessUser/${data.id}`;
	try {
		const result: any = await request(UPDATE_BUSINESS_USER, 'PUT', data);
		if (result.status) {
			return { status: true, ...result.data };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function deleteBusinessUserRequest(id: string) {
	const DELETE_BUSINESS_USER = `businessUser/${id}`;
	try {
		const result: any = await request(DELETE_BUSINESS_USER, 'DELETE');
		if (result.status) {
			return { status: true, ...result.data };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}
