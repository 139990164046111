import { request } from 'src/utils/request';

export async function getFeedbacksRequest(page: number) {
	const GET_FEEDBACKS = `feedback/getFeedbacks?page=${page}&pageSize=10`;
	try {
		const result: any = await request(GET_FEEDBACKS, 'GET');
		if (result) {
			return { status: true, ...result.data };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function getFeedbackRequest(feedbackId: string) {
	const GET_FEEDBACK = `feedback/getFeedback/${feedbackId}`;
	try {
		const result: any = await request(GET_FEEDBACK, 'GET');
		if (result) {
			return { ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function addFeedbackRequest(params: any) {
	const GET_FEEDBACK = `userComment`;
	try {
		const result: any = await request(GET_FEEDBACK, 'POST', params);
		if (result) {
			return { ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}

export async function deleteFeedbackRequest(feedbackId: string) {
	const DELETE_FEEDBACK = `feedback/deleteFeedback/${feedbackId}`;
	try {
		const result: any = await request(DELETE_FEEDBACK, 'DELETE');
		if (result) {
			return { ...result };
		} else {
			return { status: false, message: result.responseMessage };
		}
	} catch (err) {
		console.log('error', err);
		return { status: false, message: 'Error' };
	}
}
