import { combineReducers } from 'redux';

import app from './app';
import user from './user';
import camp from './camp';
import feedback from './feedback';

export const rootReducer = combineReducers({
	app,
	user,
	camp,
	feedback,
});

interface Dic {
	[x: string]: any;
}

export type RootState = {
	app: {
		loading: boolean;
		language: string;
	};
	user: {
		userData: any;
		users: Array<any>;
		count: number;
		loading: boolean;
		businessUsers: Array<any>;
		businessCount: number;
		businessLoading: boolean;
	};
	product: {
		// configData: Config;
		// categories: CategoryTree[];
		loading: boolean;
		isDataChanged: boolean;
	};
	camp: {
		loading: boolean;
		userLoading: boolean;
		tabItem: string;
		subTabItem: string;
		camps: Array<any>;
		count: number;
		userCamps: Array<any>;
		userCount: number;
		userPage: number;
		configData: any; // FIXME
		categoryImage: Array<any>;
	};
	cms: {
		tabItem: string;
		templateData: Array<any>;
		contentData: Array<any>;
		activeTemplateId: string;
		loading: boolean;
		cmsConfig: any;
	};
	feedback: {
		feedbacks: any;
		loading: boolean;
		count: number;
		page: number;
	};
	promotion: {
		loading: boolean;
		// promotions: PromotionObject[];
		tabItem: string;
		subTabItem: string;
		selectedConditions: any[];
		selectedConditionObject: any;
		selectedGift: any;
		selectedGiftObject: any;
	};
};
